import React from 'react';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import landingEventSources from 'shared/sources/landings/landingsEvents';
import { ModalFunctions } from '../../../../../interfaces/modal';
import withLandingData from '../../helpers/withLandingData';
import EventsCreate from './EventsCreate';
import EventsUpdate from './EventsUpdate';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  data: any;
  modalState: string;
  showDeleteModal: () => void;
  lng: string;
  onChangeLng: any;
  setFormData: (data: any) => any;
  updateData: (translations: any) => any;
  updateTranslations: (translations: any) => any;
  modalTab: number;
}

const LandingsEventModal: React.FC<ModalProps> = (props) => {
  const {
    closeModal,
    forceCloseModal,
    showDeleteModal,
    updateTranslations,
    updateData,
    onChangeLng,
    modalState,
    setFormData,
    data,
    lng,
    modalTab,
  } = props;

  if (modalState === MODAL_STATES.CREATE) {
    return (
      <EventsCreate lng={lng} setFormData={setFormData} closeModal={closeModal} onChangeLng={onChangeLng} />
    );
  }

  return (
    <EventsUpdate
      tabPoint={modalTab}
      forceCloseModal={forceCloseModal}
      closeModal={closeModal}
      data={data}
      lng={lng}
      onChangeLng={onChangeLng}
      updateData={updateData}
      showDeleteModal={showDeleteModal}
      updateTranslations={updateTranslations}
    />
  );
};

export default withLandingData(LandingsEventModal, {
  translation: 'events',
  sources: landingEventSources,
  emitUpdateTableAfterSave: true,
});
