import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const companyId = getCookie(COOKIES.COMPANY);
interface AttachmentsType {
  id: number;
  title: string;
  code: string;
}

interface AttachmentsTypes {
  main_image: AttachmentsType;
  cover: AttachmentsType;
  gallery: AttachmentsType;
  gallery_webp: AttachmentsType;
}

const attachmentsSource = {
  async getPersonsAttachmentsTypes(): Promise<AttachmentsTypes> {
    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/persons/attachment_types`);

    return response.data.results.reduce((acc, item) => {
      acc[item.code] = item;

      return acc;
    }, {});
  },
  async getPlacesAttachmentsTypes(): Promise<AttachmentsTypes> {
    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/places/attachment_types`);

    return response.data.results.reduce((acc, item) => {
      acc[item.code] = item;

      return acc;
    }, {});
  },
  async getEventsAttachmentsTypes(): Promise<AttachmentsTypes> {
    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/events/attachment_types`);

    return response.data.results.reduce((acc, item) => {
      acc[item.code] = item;

      return acc;
    }, {});
  },

  async getAttachmentsTypes(entityType: string) {
    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/attachment_types`, {
      params: {
        entity_type: entityType,
      },
    });

    return response.data.results.reduce((acc, item) => {
      acc[item.code] = item;

      return acc;
    }, {});
  },

  async createEventsAttachments(event_id, attachmentsData) {
    const promises = attachmentsData.map((attachment) =>
      api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${event_id}/attachments`, {
        data: attachment.data,
        attachment_type_id: attachment.attachment_type_id,
        alt: attachment.alt,
        title: attachment.title,
      }),
    );

    const responses = await Promise.all(promises);
    return responses;
  },

  deleteEventsAttachments(event_id: number, attachment_id: number) {
    const response = api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${event_id}/attachments/${attachment_id}`,
    );

    return response;
  },

  async editEventsAttachments(event_id: number, attachment_id: number, attachmentsData) {
    const promises = attachmentsData.map((attachment) =>
      api.patch(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/events/${event_id}/attachments/${attachment_id}`,
        {
          data: attachment.data,
          alt: attachment.alt,
          title: attachment.title,
        },
      ),
    );

    const responses = await Promise.all(promises);
    return responses;
  },

  async createPlacesAttachments(event_id, attachmentsData) {
    const promises = attachmentsData.map((attachment) =>
      api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places/${event_id}/attachments`, {
        data: attachment?.data,
        attachment_type_id: attachment?.attachment_type_id,
        alt: attachment?.alt,
        title: attachment?.title,
      }),
    );

    const responses = await Promise.all(promises);
    return responses;
  },

  deletePlacesAttachments(event_id: number, attachment_id: number) {
    const response = api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places/${event_id}/attachments/${attachment_id}`,
    );

    return response;
  },

  async editPlacesAttachments(event_id: number, attachment_id: number, attachmentsData) {
    const promises = attachmentsData.map((attachment) =>
      api.patch(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/places/${event_id}/attachments/${attachment_id}`,
        {
          data: attachment.data,
          alt: attachment.alt,
          title: attachment.title,
        },
      ),
    );

    const responses = await Promise.all(promises);
    return responses;
  },

  async createPersonsAttachments({ event_id, attachmentsData }) {
    const promises = attachmentsData.map((attachment) =>
      api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons/${event_id}/attachments`, {
        data: attachment?.data,
        attachment_type_id: attachment.attachment_type_id,
        alt: attachment?.alt,
        title: attachment?.title,
      }),
    );

    const responses = await Promise.all(promises);
    return responses;
  },

  deletePersonsAttachments(event_id: number, attachment_id: number) {
    const response = api.delete(
      `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons/${event_id}/attachments/${attachment_id}`,
    );

    return response;
  },

  async editPersonsAttachments(event_id: number, attachment_id: number, attachmentsData) {
    const promises = attachmentsData.map((attachment) =>
      api.patch(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/persons/${event_id}/attachments/${attachment_id}`,
        {
          data: attachment.data,
          alt: attachment.alt,
          title: attachment.title,
        },
      ),
    );

    const responses = await Promise.all(promises);
    return responses;
  },
};

export default attachmentsSource;
