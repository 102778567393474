import React from 'react';
import { TABLE_EVENTS } from 'shared/constants/EVENTS';
import Emmiter from 'shared/services/Emmiter';
import qnaSources from 'shared/sources/reference/qna';
import QnAListForm from './form';

interface QnAListCreateProps {
  onSuccess: (data: any) => any;
  closeModal: () => void;
  onChangeLng: () => void;
}

const QnAListCreate: React.FC<QnAListCreateProps> = (props) => {
  const { onSuccess, closeModal, onChangeLng } = props;

  const handleCreate = async (formData) => {
    const formLang = formData.lng;
    const { ru: placeData } = formData;

    const response = await qnaSources.qnaItemCreate(placeData);
    Emmiter.emit(TABLE_EVENTS.UPDATE_TABLE);
    return qnaSources.qnaItemCreateTranslation(response.data.id, placeData, formLang);
  };

  return (
    <QnAListForm
      title="Добавить QnA"
      onSubmit={handleCreate}
      onSuccess={onSuccess}
      closeModal={closeModal}
      onChangeLng={onChangeLng}
      initialValues={{
        item_create: true,
      }}
    />
  );
};

export default QnAListCreate;
