import styled, { css } from 'styled-components';

interface FormWrapperProps {
  fluid?: boolean;
  inRow?: boolean;
  stretch?: boolean;
}

export default {
  FormWrapper: styled.div<FormWrapperProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: ${({ fluid }) => fluid && 1};

    & > form {
      display: flex;
      flex-direction: ${(props) => (props.inRow ? 'row' : 'column')};
      flex-grow: ${({ fluid }) => fluid && 1};
    }

    ${(props) =>
      props.stretch &&
      css`
        height: 100%;

        & > form {
          height: 100%;
        }
      `}
  `,

  SpinnerContainer: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    z-index: 1;
  `,

  ErrorWrapper: styled.div`
    margin-bottom: 15px;
    text-align: center;
  `,

  Row: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  `,

  Switcher: styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.64);
  `,

  SwitcherSelect: styled.div`
    width: 130px;
    margin-left: 16px;

    select {
      height: 32px;
      padding-left: 8px;
      font-size: 14px;
      line-height: 18px;
    }
  `,
};
