import { Error, Label } from 'components/FormControls';
import Icon from 'components/Icon';
import dynamic from 'next/dynamic';
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import ICONS from 'shared/constants/ICONS';
import REGEXP from 'shared/constants/REGULAR_EXPRESSIONS';
import { useToggler } from 'shared/lib/toggler';
import { ImageModal } from './image-modal';
import { editorImageModal } from './image-modal/model';
import { editorLinkModal } from './link-modal/model';
import { LinkModal } from './link-modal/ui/ui';
import { SourceCodeModal } from './source-code';
import { sourceCodeToggler } from './source-code/model';
import Styled from './styles';

const ReactQuill = dynamic(
  async () => {
    const { default: RQ } = await import('react-quill');

    return ({ forwardedRef, ...props }) => <RQ ref={forwardedRef} {...props} />;
  },
  {
    ssr: false,
  },
);

interface Props {
  key?: string;
  label?: string;
  helper?: string;
  input?: any;
  meta?: any;
  maxValue?: number;
  inModal?: boolean;
  id?: string;
  placeholder?: string;
}

export const Editor: React.FC<Props> = ({ input, id, meta, label, helper, maxValue, placeholder }) => {
  const quillInstanceRef = React.useRef(false);

  const { open: linkModal } = useToggler(editorLinkModal.toggler);
  const { open: imageModal } = useToggler(editorImageModal.toggler);
  const { open: sourceCodeOpen } = useToggler(sourceCodeToggler);

  const openLinkModal = () => {
    linkModal(quillInstanceRef);
  };

  const openImageModal = () => {
    imageModal(quillInstanceRef);
  };

  const openSourceCodeModal = (value) => {
    sourceCodeOpen({ value, input });
  };

  const replaceUndefinedWithP = (str) => {
    const regex = /undefined/g;
    return str.replace(regex, 'p');
  };

  return (
    <Styled.Container meta={meta}>
      <Styled.Label>
        {label && <Label>{label}</Label>}
        {helper && <Styled.Helper>{helper}</Styled.Helper>}
      </Styled.Label>
      <Styled.QuillWrapper>
        <Styled.ToolbarWrapper id={id} className="ql-snow">
          <Styled.HeadingSelect className="ql-header" onChange={(e) => e.persist()}>
            <Styled.Option value="1" title="H1">
              Заголовок 1
            </Styled.Option>
            <Styled.Option value="2" title="H2">
              Заголовок 2
            </Styled.Option>
            <Styled.Option value="3" title="H3">
              Заголовок 3
            </Styled.Option>
            <Styled.Option value="0" selected>
              Абзац
            </Styled.Option>
          </Styled.HeadingSelect>

          <Styled.Span className="ql-formats">
            <Styled.Button className="ql-bold">Bold</Styled.Button>
            <Styled.Button className="ql-italic">Italic</Styled.Button>
            <Styled.Button className="ql-underline">Italic</Styled.Button>
          </Styled.Span>
          <Styled.Span className="ql-formats">
            <Styled.Button type="button" onClick={openLinkModal}>
              <Icon width="13px" src={ICONS.LINK}></Icon>
            </Styled.Button>
          </Styled.Span>
          <Styled.Span className="ql-formats">
            <Styled.Button type="button" onClick={openImageModal}>
              <Icon width="16px" src={ICONS.QUILL_IMAGE}></Icon>
            </Styled.Button>
          </Styled.Span>
          <Styled.Span className="ql-formats">
            <Styled.Button type="button" onClick={() => openSourceCodeModal(input.value)}>
              <Icon width="14px" src={ICONS.PEN}></Icon>
            </Styled.Button>
          </Styled.Span>
        </Styled.ToolbarWrapper>
        <ReactQuill
          forwardedRef={quillInstanceRef}
          modules={{
            toolbar: `#${id}`,
          }}
          placeholder={placeholder}
          theme="snow"
          value={input.value || ''}
          onChange={(content) => {
            input.onChange(replaceUndefinedWithP(content));
          }}
        />
        <SourceCodeModal />
        <LinkModal />
        <ImageModal />
      </Styled.QuillWrapper>

      {maxValue && (
        <Styled.Count>
          {input.value.replace(REGEXP.REMOVE_HTML_TAGS, '').length} / {maxValue}
        </Styled.Count>
      )}
      <Styled.Error>
        <Error meta={meta} />
      </Styled.Error>
    </Styled.Container>
  );
};
