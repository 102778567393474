import React from 'react';
import { Field } from 'react-final-form';
import {
  Input,
  InputWithSelect,
  FormDescription,
  SmallFormDescription,
  FormControl,
  Checkbox,
} from 'components/FormControls';
import { required, composeValidators, maxValue } from 'shared/helpers/validations';
import { LangForm } from 'components/Form';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Styled from './styles';
import landingsTagsSources from 'shared/sources/landings/landingsTags';
import MODALS from 'shared/constants/MODALS';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { renderContentGroups } from 'shared/helpers/optionsRenderers';

interface FormProps {
  title: string;
  forceCloseModal?: ModalFunctions['forceCloseModal'];
  modalData?: any;
  isEdit?: boolean;
  openRouterModal?: ModalFunctions['openRouterModal'];
  onChangeLng: () => any;
  onSubmit: (data: any) => any;
  onSuccess?: (data: any) => any;
  initialValues?: any;
  children?: any;
}

const LandingsCustomPageForm: React.FC<FormProps> = ({
  children,
  title,
  openRouterModal,
  modalData,
  isEdit,
  onChangeLng,
  ...rest
}) => {
  const openConstructorModal = () => {
    openRouterModal({ modalType: MODALS.PAGE_CONSTRUCTOR_MODAL, data: modalData });
  };

  return (
    <LangForm
      stretch
      {...rest}
      stashLanguages={!isEdit}
      onChangeLng={onChangeLng}
      render={({ handleSubmit, i18n, switcher }) => (
        <form onSubmit={handleSubmit}>
          <Styled.GlobalStyles isEdit={isEdit} />
          <Styled.TitleWrapper isEdit={isEdit}>
            <Modal.Title isSmall>{title}</Modal.Title>
          </Styled.TitleWrapper>
          <Styled.Sections>
            <Styled.Section>{switcher}</Styled.Section>
            <Styled.Section>
              <FormDescription width={128}>
                Основное
                <SmallFormDescription>Название страницы и ее ссылка</SmallFormDescription>
              </FormDescription>
              <Styled.Rows>
                <Field name={`${i18n.language}.id`} component="input" type="hidden" />
                <Field type="hidden" name={`${i18n.language}.manual`} component="input" value="false" />
                <Styled.Row>
                  <Field name={`${i18n.language}.name`} validate={composeValidators(required, maxValue(255))}>
                    {({ input, meta }) => <Input autoFocus {...input} meta={meta} label="Название" />}
                  </Field>
                </Styled.Row>
                {i18n.language === LANGUAGES.RU && (
                  <Styled.Row>
                    <Field
                      name={`${i18n.language}.path`}
                      validate={composeValidators(required, maxValue(255))}
                    >
                      {({ input, meta }) => <Input {...input} meta={meta} label="Ссылка" />}
                    </Field>
                  </Styled.Row>
                )}
              </Styled.Rows>
            </Styled.Section>
            {i18n.language === LANGUAGES.RU && (
              <Styled.SelectionSelect>
                <Styled.Section>
                  <FormDescription width={128}>
                    Теги
                    <SmallFormDescription>Добавление тегов</SmallFormDescription>
                  </FormDescription>
                  <Styled.Row>
                    <Field name={`${i18n.language}.content_groups`}>
                      {({ input, meta }) => (
                        <InputWithSelect
                          isAsync
                          isMulti
                          isCreatable
                          label="Теги"
                          searchQueryName="search_string"
                          optionsRenderer={renderContentGroups}
                          route={landingsTagsSources}
                          meta={meta}
                          {...input}
                        />
                      )}
                    </Field>
                    <Styled.Description>
                      Контент из раздела «Лендинги» с одинаковыми тегами будет использоваться на сайте
                    </Styled.Description>
                  </Styled.Row>
                </Styled.Section>
              </Styled.SelectionSelect>
            )}
            {isEdit && (
              <Styled.Section>
                <FormDescription width={128}>
                  Конструктор страницы
                  <SmallFormDescription>Создание страницы</SmallFormDescription>
                </FormDescription>
                <FormControl>
                  <Button type="button" onClick={openConstructorModal}>
                    Перейти в конструктор
                  </Button>
                </FormControl>
              </Styled.Section>
            )}
            {i18n.language === LANGUAGES.RU && (
              <Styled.Section>
                <FormDescription width={128}>Показывать в меню</FormDescription>
                <Styled.Row>
                  <Field name={`${i18n.language}.show_in_menu`} type="checkbox">
                    {({ input, meta }) => <Checkbox meta={meta} {...input} />}
                  </Field>
                </Styled.Row>
              </Styled.Section>
            )}
          </Styled.Sections>
          <Modal.Footer contentWidth="880px">{children}</Modal.Footer>
        </form>
      )}
    />
  );
};

export default LandingsCustomPageForm;
