import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { Router, withRouter } from 'next/router';
import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';
import { getQueryByFormValues } from '../helpers/query';
import { queryFilter } from './queryFilter';

interface FormQueryParamsProps {
  form: {
    change: Function;
  };
  values: any;
  router: Router;
  withoutPagination?: boolean;
  clearRemoved?: boolean;
  ignoredQueries?: string[];
  mergedQuery?: string[];
}

class FormQueryParams extends Component<FormQueryParamsProps> {
  unmounted: boolean;

  debouncedUpdate: any;

  static defaultProps = {
    withoutPagination: false,
    clearRemoved: true,
    ignoredQueries: [],
    mergedQuery: [],
  };

  constructor(props) {
    super(props);
    this.unmounted = false;
    this.debouncedUpdate = debounce(this.update, 1000);
  }

  componentDidUpdate(prevProps) {
    const { values, router, withoutPagination, ignoredQueries, mergedQuery } = this.props;

    if (!isEqual(values, prevProps.values)) {
      const params = getQueryByFormValues({
        router,
        values,
        mergedQuery,
        ignoredQueries,
        withoutPagination,
      });

      this.debouncedUpdate(params);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  update = (params) => {
    if (this.unmounted) return;
    const { ignoredQueries } = this.props;

    queryFilter(omit(params, ignoredQueries), this.props.clearRemoved);
  };

  render() {
    return null;
  }
}

const Form = (props: any) => (
  <FormSpy {...props} subscription={{ values: true }} component={FormQueryParams} />
);

export default withRouter(Form);
