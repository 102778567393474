import React from 'react';
import CheckIcon from '../../../static/icons/check-white.svg';
import Styled from './styles';

interface ButtonProps {
  id: number;
  step: string;
  active: boolean;
  current: boolean;
  isVertical?: boolean;
  mobileHorizontal?: boolean;
  onSelect: (number) => void;
  isInvalid?: boolean;
  stepsPreview?: any;
  horizontalOn?: string;
}

const ProgressBarButton: React.FC<ButtonProps> = ({
  active,
  step,
  current,
  isVertical,
  id,
  onSelect,
  mobileHorizontal,
  isInvalid,
  stepsPreview,
  horizontalOn,
}) => {
  const selectStep = () => {
    onSelect(id);
  };

  return (
    <Styled.Button
      active={active}
      isVertical={isVertical}
      isInvalid={isInvalid}
      horizontalOn={horizontalOn}
      mobileHorizontal={mobileHorizontal}
      selectable={!!onSelect}
      onClick={onSelect ? selectStep : null}
      data-selenium={`step-${step}`}
    >
      {stepsPreview ? stepsPreview[step] : step}
      <Styled.Circle
        active={active}
        current={current}
        isVertical={isVertical}
        horizontalOn={horizontalOn}
        mobileHorizontal={mobileHorizontal}
      >
        {active && !current && <CheckIcon fill="#fff" />}
      </Styled.Circle>
    </Styled.Button>
  );
};

export default ProgressBarButton;
