import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import CallContextFormStyled from '../../../CallContextForm/styles';

export default {
  Wrapper: styled.div`
    margin: -24px;
    height: 100%;

    @media (max-width: ${BREAKPOINTS.MD}) {
      margin: -32px;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      margin: -32px -16px;
    }
  `,

  CallContextOverlay: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  `,

  CallContextFormWrapper: styled.div`
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 2;

    ${CallContextFormStyled.Form} {
      border-radius: 8px;
    }
  `,

  Bar: styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

    @media (max-width: ${BREAKPOINTS.MD}) and (orientation: landscape) {
      left: auto;
      right: 0;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      width: 200px;
      padding-top: 60px;

      & > button {
        width: 100%;
      }
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      flex-direction: column;
      align-items: flex-start;

      & > button {
        width: 100%;
      }
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding: 15px;
    }
  `,

  EventData: styled.div`
    color: #313131;
    font-weight: bold;
    font-size: 20px;

    @media (max-width: ${BREAKPOINTS.SM}) {
      margin-bottom: 12px;
    }
  `,

  Description: styled.div`
    color: #585858;
    font-size: 16px;
    font-weight: normal;
  `,

  Widget: styled.div`
    height: 100%;

    @media (max-width: ${BREAKPOINTS.MD}) and (orientation: landscape) {
      width: calc(100% - 200px);
      height: 100vh;
    }
  `,

  Error: styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  `,
};
