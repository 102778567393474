import config from 'config/config';

const landingTriggersSouces = {
  list: `${config.LANDING_API_URL}/api/v1/landing/trigger`,
  root: (techName: string, lang = 'ru') =>
    `${config.LANDING_API_URL}/api/v1/landing/trigger?landing=${techName}&lang=${lang}`,
  detail: (id: string | number) => `${config.LANDING_API_URL}/api/v1/landing/trigger/${id}`,
};

export default landingTriggersSouces;
