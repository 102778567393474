import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import last from 'lodash/last';
import omit from 'lodash/omit';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import ERRORS from 'shared/constants/ERRORS';
import WidgetThirdStep from './WidgetThirdStep';
import { createWidget, editWidget } from '../../../../../features/widgets';

interface ContainerProps {
  t: (value: string, params?: any) => string;
  onSubmit: (data: any) => any;
  data?: any;
}

const ThirdStepContainer: React.FC<ContainerProps> = (props) => {
  const { data, onSubmit } = props;
  const dispatch = useDispatch();

  const getEventDates = () => {
    if (
      data.eventDates &&
      data.eventDates.filter((item) => item.outdated).length === data.eventDates.length
    ) {
      return data.eventDates;
    }

    if (data.eventDates) {
      return data.eventDates.filter((item) => !item.outdated);
    }

    return null;
  };

  const eventDates = getEventDates();
  const [state, setState] = useState({
    isLoading: true,
    selectedDate: [],
    data,
  });

  const onUpdateParentWidget = (action) => {
    if (action.error) {
      NotificationManager.error(ERRORS.DEFAULT);
      return;
    }

    onSubmit(action.payload.data);
  };

  const submit = (submitData) => {
    const isPeriodical = get(data.event, 'is_periodical');

    if (isPeriodical && state.selectedDate) {
      if (!state.selectedDate.length) {
        return dispatch(
          editWidget({
            id: data.id,
            markups: submitData.markups,
            use_currency: submitData.use_currency,
            currency_id: submitData.currency_id,
          }),
          // @ts-ignore
        ).then(onUpdateParentWidget);
      }

      const ids = state.selectedDate.map((item) => item.value);

      return Promise.all([
        ...ids.map((event) => {
          const childrenWidget = data.children.find((child) => child.event_id === event);

          if (childrenWidget) {
            return dispatch(
              editWidget(
                omit(
                  {
                    ...childrenWidget,
                    active: data.active,
                    markups: submitData.markups,
                    use_currency: submitData.use_currency,
                    currency_id: submitData.currency_id,
                  },
                  ['scope', 'parent_id'],
                ),
              ),
            );
          }

          return dispatch(
            createWidget(
              omit(
                {
                  ...data,
                  event_id: event,
                  parent_id: data.id,
                  markups: submitData.markups,
                  use_currency: submitData.use_currency,
                  currency_id: submitData.currency_id,
                },
                ['scope', 'id', 'children'],
              ),
            ),
          );
        }),
      ]).then((results) => {
        const error = get(results, '[0].error');

        if (error) {
          NotificationManager.error(ERRORS.DEFAULT);
          return;
        }

        onSubmit({
          children: data.children.map((child) => {
            const updatedChild: any = results.find((result: any) => result.payload.id === child.id);

            return updatedChild ? updatedChild.payload : child;
          }),
        });
      });
    }

    return dispatch(
      editWidget({
        id: data.id,
        markups: submitData.markups,
        use_currency: submitData.use_currency,
        currency_id: submitData.currency_id,
      }),
      // @ts-ignore
    ).then(onUpdateParentWidget);
  };

  const onChangeDate = (date) => {
    if (date.length === 1) {
      const lastDate: { value: number } = last(date);
      const id = lastDate.value;
      const childWidget = data.children.find((child) => child.event_id === id);

      return setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          markups: childWidget ? childWidget.markups : data.markups,
          currency_id: childWidget ? childWidget.currency_id : data.currency_id,
          use_currency: childWidget ? childWidget.use_currency : data.use_currency,
        },
        isLoading: false,
        selectedDate: date,
      }));
    }

    return setState((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        markups: data.markups,
        currency_id: data.currency_id,
        use_currency: data.use_currency,
      },
      selectedDate: date,
      isLoading: false,
    }));
  };

  useEffect(() => {
    if (state.selectedDate) {
      onChangeDate(state.selectedDate);
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoading: !prevState.isLoading,
      }));
    }
  }, []);

  const { data: stateData, selectedDate, isLoading } = state;

  const componentProps = {
    ...props,
    onSubmit: submit,
    isLoading,
    data: stateData,
    eventDates:
      eventDates &&
      eventDates.map((item) => ({
        ...item,
        handleClick: onChangeDate,
      })),
    selectedDate,
    onChangeDate,
  };

  return <WidgetThirdStep {...componentProps} />;
};

export default ThirdStepContainer;
