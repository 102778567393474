import React, { useState } from 'react';
import { FieldMetaState } from 'react-final-form';
import moment from 'moment';
import { TimePicker as AntTimePicker } from 'antd';
import Portal from '../../Portal';
import Styled from './styles';
import Label from '../LabelNew';
import Error from '../Error';

interface TimePickerProps {
  format?: string;
  label: string;
  input: {
    onChange: (value: any) => void;
    value: any;
  };
  meta: FieldMetaState<any>;
  iconLeft?: boolean;
  defaultNowTime?: boolean;
}

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const { format = 'HH:mm', label, input, meta } = props;
  const [opened, setOpened] = useState(false);

  const [prevTime, setPrevTime] = useState(null);

  const handleChange = (value) => {
    input.onChange(value);

    if (prevTime && value.minutes() !== prevTime.minutes()) {
      setOpened(false);
    }
    if (prevTime && value.minutes() === prevTime.minutes() && value.hours() === prevTime.hours()) {
      setOpened(false);
    }

    setPrevTime(value);
  };

  const handleOpenChange = () => {
    setOpened(true);
  };

  const closePicker = () => {
    setOpened(false);
  };

  return (
    <Styled.Container meta={meta}>
      {opened && (
        <Portal>
          <Styled.Overlay onClick={closePicker} />
        </Portal>
      )}
      {label && (
        <Label withIcon={props.iconLeft} meta={meta} value={input.value}>
          {label}
        </Label>
      )}
      <AntTimePicker
        format={format}
        open={opened}
        onOpenChange={handleOpenChange}
        placeholder={null}
        value={input.value}
        onChange={handleChange}
        defaultOpenValue={props.defaultNowTime ? moment() : moment('1700', 'hmm')}
      />
      <Error meta={meta} />
    </Styled.Container>
  );
};

export default TimePicker;
