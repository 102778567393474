import React from 'react';
import { withRouter } from 'next/router';
import { Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import { required } from 'shared/helpers/validations';
import landingsTagsSources from 'shared/sources/landings/landingsTags';
import withLandingData from '../withLandingData';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Modal from 'components/Modal';
import Form from 'components/Form';
import Button from 'components/Button';
import { InputWithSelect } from 'components/FormControls';
import Styled from './styles';
import api from 'shared/services/api';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { renderContentGroups } from 'shared/helpers/optionsRenderers';

interface TagMergeModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  updateList: () => void;
}

const TagMergeModal: React.FC<TagMergeModalProps> = (props) => {
  const mergeTags = (data) => {
    api
      .post(landingsTagsSources.merge(props.data.id), {
        content_groups: data.content_groups.map((group) => group.value),
      })
      .then((action: any) => {
        if (action.error) {
          return NotificationManager.error('Ошибка');
        }

        props.closeModal();
        props.updateList();
        return NotificationManager.success('Успешно');
      })
      .catch((err) => new Error(err));
  };

  return (
    <Styled.Container>
      <Modal.Title>Объединение тегов</Modal.Title>
      <Styled.Name>{props.data.name}</Styled.Name>
      <Form
        onSubmit={mergeTags}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="content_groups" validate={required}>
              {({ input, meta }) => (
                <InputWithSelect
                  isAsync
                  isMulti
                  label="Теги"
                  searchQueryName="search_string"
                  route={landingsTagsSources}
                  optionsRenderer={renderContentGroups}
                  meta={meta}
                  {...input}
                />
              )}
            </Field>
            <Modal.Footer>
              <Button transparent type="button" onClick={props.closeModal}>
                Отмена
              </Button>
              <Button>Связать</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

export default withCheckIsMobile(
  withLandingData(withRouter(TagMergeModal as any), {
    sources: landingsTagsSources,
  }),
);
