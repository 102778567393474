import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Input, InputWithSelect } from 'components/FormControls';
import { LangForm } from 'components/Form';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Styled from './styles';
import landingSources from 'shared/sources/landings/landingsTemplates';
import { ModalFunctions } from '../../../../../interfaces/modal';
import withLandingData from '../withLandingData';
import PAGES from 'shared/constants/PAGES';
import landingsTemplateSets from 'shared/sources/landings/landingsTemplateSets';
import LOCALES from 'shared/constants/LOCALES';
import { withTranslation } from '../../../../i18n';
import { required } from 'shared/helpers/validations';
import { selectAccount } from '../../../../entities/account/reducer';
import { renderLandingsTemplates } from 'shared/helpers/optionsRenderers';
import { editTranslationData } from 'shared/helpers/form';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  showDeleteModal: () => void;
  modalState?: string;
  lng: string;
  t: (value: string, params?: { [key: string]: any }) => string;
  onChangeLng: any;
  updateList: (data: any) => void;
  onUpdate: (data: any) => void;
  create: (data: any) => void;
  update: (data: any) => void;
}

const LandingsTemplateSetModal: React.FC<ModalProps> = (props) => {
  const { data, onChangeLng, updateList, onUpdate, t, create, update, lng } = props;
  const account: any = useSelector(selectAccount);
  const isEdit = props.data.id;

  const initialValues = {
    ...data,
    oferta: data.oferta && { label: data.oferta.name, value: data.oferta.id },
    privacy_policy: data.privacy_policy && {
      label: data.privacy_policy.name,
      value: data.privacy_policy.id,
    },
    pay_and_delivery: data.pay_and_delivery && {
      label: data.pay_and_delivery.name,
      value: data.pay_and_delivery.id,
    },
    about: data.about && { label: data.about.name, value: data.about.id },
    company_id: account.company.id,
    guarantee: data.guarantee && { label: data.guarantee.name, value: data.guarantee.id },
  };

  const handleUpdate = (formData) => {
    const dataForCompare = formData[lng];

    if (isEqual(dataForCompare, initialValues)) {
      return {};
    }

    return update(formData);
  };

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title>{t(`landings:template_set.${isEdit ? 'edit' : 'create'}_title`)}</Modal.Title>
      <LangForm
        stashLanguages={!isEdit}
        onChangeLng={onChangeLng}
        onSubmit={isEdit ? handleUpdate : create}
        onSuccess={(submitData) => {
          if (isEdit) {
            return onUpdate(submitData);
          }
          return updateList(submitData);
        }}
        initialValues={initialValues}
        render={({ handleSubmit, i18n }) => (
          <form onSubmit={handleSubmit}>
            <Styled.Fields>
              <Field name={`${i18n.language}.name`} validate={required}>
                {({ input, meta }) => (
                  <Input label={t('landings:template_set.name')} {...input} meta={meta} />
                )}
              </Field>
              <Field name={`${i18n.language}.privacy_policy`}>
                {({ input, meta }) => (
                  <InputWithSelect
                    isAsync
                    label={t('landings:template_set.privacy')}
                    route={landingSources}
                    searchQueryName="search_string"
                    optionsRenderer={renderLandingsTemplates}
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>
              <Field name={`${i18n.language}.oferta`}>
                {({ input, meta }) => (
                  <InputWithSelect
                    isAsync
                    label={t('landings:template_set.oferta')}
                    optionsRenderer={renderLandingsTemplates}
                    route={landingSources}
                    searchQueryName="search_string"
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>
              <Field name={`${i18n.language}.pay_and_delivery`}>
                {({ input, meta }) => (
                  <InputWithSelect
                    isAsync
                    label={t('landings:template_set.delivery')}
                    route={landingSources}
                    searchQueryName="search_string"
                    optionsRenderer={renderLandingsTemplates}
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>
              <Field name={`${i18n.language}.about`}>
                {({ input, meta }) => (
                  <InputWithSelect
                    isAsync
                    label={t('landings:template_set.about')}
                    route={landingSources}
                    searchQueryName="search_string"
                    optionsRenderer={renderLandingsTemplates}
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>
              <Field name={`${i18n.language}.guarantee`}>
                {({ input, meta }) => (
                  <InputWithSelect
                    isAsync
                    label={t('landings:template_set.guarantee')}
                    route={landingSources}
                    searchQueryName="search_string"
                    optionsRenderer={renderLandingsTemplates}
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>
            </Styled.Fields>
            <Modal.Footer>
              <Button type="button" transparent onClick={props.closeModal}>
                {t('landings:template_set.buttons.cancel')}
              </Button>
              <Button type="submit">
                {t(`landings:template_set.buttons.${isEdit ? 'save' : 'create'}`)}
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

const mappedSubmitData = (item) => ({
  ...item,
  about: item.about?.value,
  pay_and_delivery: item.pay_and_delivery?.value,
  oferta: item.oferta?.value,
  privacy_policy: item.privacy_policy?.value,
  guarantee: item.guarantee?.value,
});

export default withLandingData(withTranslation([LOCALES.FORMS, LOCALES.LANDINGS])(LandingsTemplateSetModal), {
  page: PAGES.LANDING_TEMPLATE_SETS,
  translation: 'template_set',
  sources: landingsTemplateSets,
  mappedCreateData: (submitData) => editTranslationData(submitData, (item) => mappedSubmitData(item)),
  mappedUpdateData: (submitData) => editTranslationData(submitData, (item) => mappedSubmitData(item)),
});
