import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { NotificationManager } from 'react-notifications';
import isUndefined from 'lodash/isUndefined';
import Spinner from '../../../Spinner';
import api from 'shared/services/api';
import UploadSources from 'shared/sources/upload';
import TrashIcon from '../../../../../static/icons/trash.svg';
import PenIcon from '../../../../../static/icons/pen.svg';
import Styled from '../styles';
import FileAttributesModal from '../FileAttributesModal';

interface FileArrayInputItemProps {
  file?: any;
  onDelete: (id: string | number) => void;
  onEdit?: (id: string | number, data: any) => void;
  onLoad: (id: string | number, link: string, linkWebp?: string) => void;
  onFail: (id: string | number) => void;
  moveFile: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  withAttributes?: boolean;
}

const FileArrayInputItem: React.FC<FileArrayInputItemProps> = ({
  file = {},
  onLoad,
  onEdit,
  onFail,
  onDelete,
  index,
  moveFile,
  withAttributes,
}) => {
  const ref = useRef(null);
  const [modalVisible, toggleModal] = useState(false);

  useEffect(() => {
    if (file.file) {
      const formData = new FormData();
      formData.append('image', file.file);

      api
        .post(UploadSources.root, formData)
        .then((response) => {
          const { link, link_webp: linkWebp } = response.data;
          onLoad(file.id, link, linkWebp);
          return link;
        })
        .catch((error) => {
          onFail(file.id);
          if (error.response.status === 413) {
            return NotificationManager.error('Вы попытались загрузить слишком большой файл', 'Ошибка');
          }

          return NotificationManager.error(`Ошибка. ${file.file.name} не удалось загрузить`);
        });
    }
  }, []);

  const deleteImage = () => {
    onDelete(file.id);
  };

  const openAttributesModal = () => {
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };

  const editAttributes = ({ alt, title }) => {
    onEdit(file.id, { alt, title });
    closeModal();
  };

  const [, drop] = useDrop({
    accept: 'FILE',
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards && rightwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && hoverClientX > hoverMiddleX) {
        return;
      }
      // Dragging upwards && leftwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && hoverClientX < hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveFile(dragIndex, hoverIndex);

      // eslint-disable-next-line
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'FILE', id: file.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  let preview = file.link;

  if (file.link && !isUndefined(file.link.img)) {
    preview = file.link.img;
  }

  return (
    <Styled.PreviewContainer width={80} height={80} withPreview bg={preview} ref={ref} style={{ opacity }}>
      {!preview ? <Spinner size={30} /> : null}
      {withAttributes && (
        <Styled.EditButton onClick={openAttributesModal} type="button">
          <PenIcon />
        </Styled.EditButton>
      )}
      <Styled.DeleteButton onClick={deleteImage} type="button" data-selenium="delete-image-button">
        <TrashIcon />
      </Styled.DeleteButton>
      {modalVisible && (
        <FileAttributesModal onSubmit={editAttributes} onClose={closeModal} data={file.link} />
      )}
    </Styled.PreviewContainer>
  );
};

export default FileArrayInputItem;
