import { createFactory } from '@withease/factories';
import { createEvent, createStore, EventCallable, sample, StoreWritable } from 'effector';
import { createToggler, TogglerInstance } from 'shared/lib/toggler';

export interface createUpdateModalParams {
  loadingInitialValue?: boolean;
}

export interface UpdateModalStates<T, R> {
  toggler: TogglerInstance;
  modalOpened: EventCallable<T>;
  modalClosed: EventCallable<void>;
  $modalId: StoreWritable<R | null>;
  $loading: StoreWritable<boolean>;
}

export const createUpdateModalStates = createFactory(() => {
  const toggler = createToggler();

  const modalOpened = createEvent<{ id: number; type?: 'update' }>();
  const modalClosed = createEvent();

  const $modalId = createStore<number | null>(null);
  const $loading = createStore<boolean>(true);

  sample({
    clock: toggler.open,
    target: $modalId,
  });

  return {
    toggler,
    modalOpened,
    modalClosed,
    $modalId,
    $loading,
  };
});
