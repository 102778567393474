import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import MODALS from '../shared/constants/MODALS';

const modalsWithConfrimClose = [
  MODALS.WIDGET_MODAL,
  MODALS.WIDGET_MODAL_V2,
  MODALS.CONSTRUCTOR_MODAL,
  MODALS.PAGE_CONSTRUCTOR_MODAL,
  MODALS.WIDGET_PATTERN_MODAL,
  MODALS.SITE_MODAL,
  MODALS.LANDINGS_EVENT_MODAL,
  MODALS.ARTICLE_MODAL,
  MODALS.PERSONALITY_MODAL,
  MODALS.PLAYGROUND_MODAL,
  MODALS.SEO_MODAL,
  MODALS.COMPANY_LEGAL_INFO_MODAL,
  MODALS.COMPANY_ATOL_CONFIG_MODAL,
  MODALS.COMPANY_ADD_PROVIDER,
  MODALS.COMPANY_ADD_PAYMENT_METHOD,
  MODALS.COMPANY_EMAIL_CONFIG_MODAL,
  MODALS.ORDER_NEW_TICKETS_MODAL,
  MODALS.PARSERS_ADD_EVENT_MODAL,
  MODALS.ANNOUNCEMENT_PLANNING_PRICE_MODAL,
  MODALS.ANNOUNCEMENT_COMMENT_MODAL,
  MODALS.ORDER_EXTERNAL_ID_MODAL,
  MODALS.ORDER_REFUND_MODAL,
  MODALS.TAG_MODAL,
  MODALS.LINK_EVENT_MODAL,
  MODALS.REPORTS_CONFIG_MODAL,
  MODALS.REFERENCE_PLACE,
  MODALS.REFERENCE_HALL,
  MODALS.REFERENCE_EVENT_CATEGORY,
  MODALS.REFERENCE_EXPANSION,
  MODALS.REFERENCE_COUNTRY,
  MODALS.REFERENCE_LOCATION,
  MODALS.REFERENCE_TAG,
  MODALS.REFERENCE_PERSON,
  MODALS.REFERENCE_CURRENCY,
  MODALS.REFERENCE_SUBDOMAIN,
  MODALS.CLIENTS_MODAL,
  MODALS.CLIENTS_MERGE_MODAL,
  // NEW LANDINGS
  MODALS.CONSTRUCTOR_MODAL,
  MODALS.PAGE_CONSTRUCTOR_MODAL,
  MODALS.SITE_MODAL,
  MODALS.LANDINGS_EVENT_MODAL,
  MODALS.ARTICLE_MODAL,
  MODALS.PERSONALITY_MODAL,
  MODALS.PLAYGROUND_MODAL,
  MODALS.SEO_MODAL,
  MODALS.LANDINGS_TEMPLATE_MODAL,
  MODALS.LANDINGS_QNA_MODAL,
  MODALS.LANDINGS_TEMPLATE_SET_MODAL,
  MODALS.LANDINGS_CUSTOM_PAGE_MODAL,
  MODALS.LANDINGS_REDIRECTS_MODAL,
];

const modalsWithFullSize = [
  MODALS.WIDGET_MODAL,
  MODALS.WIDGET_MODAL_V2,
  MODALS.WIDGET_PATTERN_MODAL,

  // NEW LANDINGS
  MODALS.CONSTRUCTOR_MODAL,
  MODALS.PAGE_CONSTRUCTOR_MODAL,
  MODALS.LANDINGS_EVENT_MODAL,
  MODALS.ARTICLE_MODAL,
  MODALS.PERSONALITY_MODAL,
  MODALS.PLAYGROUND_MODAL,
  MODALS.SEO_MODAL,
  MODALS.LANDINGS_TEMPLATE_MODAL,
  MODALS.LANDINGS_QNA_MODAL,
  MODALS.REFERENCE_QnA,
  MODALS.MARKUP_UPDATE,
  MODALS.FILTERS_EDIT,
];

const configModals = (modals, config) =>
  modals.reduce((acc, curr) => {
    acc[curr] = config;

    return acc;
  }, {});

const CONFIRM_CLOSE_CONFIG = { confirmClose: true };
const FULL_SIZE_CONFIG = { fullSize: true, padding: '0px' };

const modalsWithConfrimCloseConfig = configModals(modalsWithConfrimClose, CONFIRM_CLOSE_CONFIG);
const modalsWithFullSizeConfig = configModals(modalsWithFullSize, FULL_SIZE_CONFIG);

const modalsConfigsForMerge = [modalsWithConfrimCloseConfig, modalsWithFullSizeConfig];

const mergedModalsConfig = reduce(
  modalsConfigsForMerge,
  (acc, curr) => {
    forEach(curr, (item, index) => {
      acc[index] = { ...acc[index], ...item };
    });

    return acc;
  },
  {},
);

const modalsConfig = reduce(
  mergedModalsConfig,
  (acc, curr, index) => {
    acc[index] = { modalData: curr };

    return acc;
  },
  {},
);

export default modalsConfig;
