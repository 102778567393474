import React from 'react';
import { Field } from 'react-final-form';
import { Input } from 'components/FormControls';
import { required, composeValidators, maxValue } from 'shared/helpers/validations';
import { LangForm } from 'components/Form';
import Modal from 'components/Modal';
import { ModalFunctions } from '../../../../../interfaces/modal';

interface FormProps {
  title: string;
  closeModal?: ModalFunctions['closeModal'];
  // eslint-disable-next-line no-undef
  children: JSX.Element | JSX.Element[];
  onSubmit: (data: any) => any;
  onSuccess: (data: any) => any;
  initialValues?: any;
}

const TagForm: React.FC<FormProps> = ({ children, title, ...rest }) => (
  <LangForm
    {...rest}
    render={({ handleSubmit, i18n }) => (
      <form onSubmit={handleSubmit}>
        <Modal.Title>{title}</Modal.Title>
        <Field name={`${i18n.language}.name`} validate={composeValidators(required, maxValue(255))}>
          {({ input, meta }) => <Input label="Тег" autoFocus {...input} meta={meta} />}
        </Field>
        <Modal.Footer>{children}</Modal.Footer>
      </form>
    )}
  />
);

export default TagForm;
