import styled from 'styled-components';

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Name: styled.div`
    margin: 8px 0;
    font-size: 18px;
  `,
};
