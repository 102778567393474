import Button from 'components/Button';
import FlexGrid from 'components/FlexGrid';
import Form from 'components/Form';
import { Input } from 'components/FormControls';
import Link from 'components/Link';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import { Table, PlainTable } from 'components/Table';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { withRouter } from 'next/router';
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { clearData } from 'entities/events';
import { linkParserWithEvent } from 'entities/parsers/events';
import { linkParserWithEvent as linkParserViewsers } from 'entities/parsers/viewers';
import { formatFullDate } from 'shared/helpers/formatters/date';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { scrollbar } from '../../../../app/styles/scrollbar';
import EventRow from './EventRow';
import api from 'shared/services/api';
import config from 'config/config';
import { getCookie } from 'shared/lib/cookie';
import COOKIES from 'shared/constants/COOKIES';
import { i18n } from 'next-i18next';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

let dataLength = 0;

const ERRORS = {
  'Diff between event and parser more than 1 hour.': 'Разница между событием и парсером более 1 часа.',
};

const StyledTable = styled.div`
  height: 350px;
  overflow: auto;

  & tr {
    & > td:first-child,
    & > th:first-child {
      padding-left: 24px;
    }
  }

  .infinite-scroll-component {
    ${scrollbar};
  }
`;

const StyledTr = styled.tr`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  border: 0 !important;
`;

const StyledTd = styled.td`
  padding-top: 150px;
  color: #5c5c5c;
  display: flex;
  width: 100%;
  font-size: 18px;
  justify-content: center;
  border: 0 !important;
`;

interface ModalProps {
  clearData: () => any;
  loadMoreEvents: (data: any) => any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  // eslint-disable-next-line camelcase
  linkParserWithEvent: (id: string | number, { event_id }: { event_id: string | number }) => any;
  // eslint-disable-next-line camelcase
  linkParserViewsers: (id: string | number, { event_id }: { event_id: string | number }) => any;
  modalData: any;
}

interface ModalState {
  search_string: string;
  value: string;
  inputIsTouched: boolean;
  debouncing: boolean;
  data: any[];
  meta: {
    count: number;
    page: number;
    last_page: number;
    limit: number;
  };
}

class LinkEventModal extends Component<ModalProps, ModalState> {
  debouncedLoad: any;

  route = `${config.COMMON_API_URL}/admin/v1/${getCookie(COOKIES.COMPANY)}/market/events`;

  constructor(props) {
    super(props);

    this.state = {
      search_string: props.modalData.inputValue || '',
      value: props.modalData.inputValue || '',
      inputIsTouched: false,
      debouncing: false,
      data: [],
      meta: {
        count: 0,
        page: 1,
        last_page: 1,
        limit: 25,
      },
    };

    this.renderPlaceholder = this.renderPlaceholder.bind(this);
    this.debouncedLoad = debounce(this.searchEvent, 1000);

    if (this.state.search_string) {
      this.searchEvent();
    }
  }

  componentDidMount() {
    this.props.clearData();
  }

  handleChange = ({ target: { value } }) => {
    const inputValue = value.trim();

    if (inputValue.length > 0) {
      this.setState({ value, search_string: inputValue, debouncing: true }, () => {
        this.debouncedLoad(this.state.search_string);
      });
    } else {
      this.setState({ value, inputIsTouched: true }, () => {
        setTimeout(() => {
          this.props.clearData();
        }, 1000);
      });
    }
  };

  searchEvent = (query?: { [key: string]: string | number }, isLoadingMore = false) => {
    const { search_string } = this.state;

    api
      .get(this.route, {
        params: {
          search_string,
          page: 1,
          language_code: i18n?.language || LANGUAGES.RU,
          ...query,
        },
      })
      .then((response) => {
        const { results, ...meta } = response.data;
        const data = results.map((item) => {
          const info = item.info.find((i) => i.language_code === i18n?.language);
          const placeInfo = item.event.place?.info?.find((i) => i.language_code === i18n?.language);

          const adaptedItem = {
            ...item,
            title: info?.title,
            place: {
              name: placeInfo?.title,
            },
            children: item.event.dates.map((date) => {
              const [date_start, time_start] = date.started_at.split('T');
              const [date_finish, time_finish] = date.finished_at.split('T');
              return {
                id: date.id,
                date_start,
                time_start,
                date_finish,
                time_finish,
              };
            }),
          };
          return adaptedItem;
        });

        this.setState({
          inputIsTouched: true,
          debouncing: false,
          data: isLoadingMore ? this.state.data.concat(data) : data,
          meta,
        });
      });
  };

  loadMore = () => {
    const { page } = this.state.meta;

    return this.searchEvent({ page: page + 1 }, true);
  };

  linkEvent = (data) => {
    const { parsersViewersPage } = this.props.modalData;
    const link = parsersViewersPage ? this.props.linkParserViewsers : this.props.linkParserWithEvent;
    return link(this.props.modalData.id, { event_id: data.id }).then((results) => {
      if (results.error) {
        const { error } = results.error.response.data;
        return NotificationManager.error(ERRORS[error] || error, 'Ошибка');
      }

      return results;
    });
  };

  renderPlaceholder() {
    const { debouncing, inputIsTouched } = this.state;

    if (debouncing) {
      return <Spinner />;
    }
    if (!inputIsTouched) {
      return 'Начните поиск';
    }
    return 'Ничего не найдено';
  }

  render() {
    const { meta, value } = this.state;
    const { name, when, venue, url, source, where } = this.props.modalData;
    const venueName = get(venue, 'name') || venue;
    const venueAddress = get(venue, 'address') || where;

    return (
      <>
        <Modal.Title>Связывание мероприятия</Modal.Title>
        <FlexGrid.Row justify="space-between" mt={-14}>
          <FlexGrid.Col>
            <FlexGrid.Col>
              <FlexGrid.Text size="14px">{formatFullDate(when)}</FlexGrid.Text>
            </FlexGrid.Col>
            <FlexGrid.Col mt={4} mb={4}>
              <FlexGrid.Text size="18px">{name}</FlexGrid.Text>
            </FlexGrid.Col>
            <Table.SmallDescription>
              <FlexGrid.Text size="14px">{venueName}</FlexGrid.Text>
            </Table.SmallDescription>
            <Table.SmallDescription>
              <FlexGrid.Text size="14px">{venueAddress}</FlexGrid.Text>
            </Table.SmallDescription>
          </FlexGrid.Col>
          <FlexGrid.Row justify="flex-end" align="flex-end" width="auto">
            <Link href={url} target="_blank" rel="noopener noreferrer">
              {source}
            </Link>
          </FlexGrid.Row>
        </FlexGrid.Row>
        <FlexGrid.Col mt={32}>
          <Input
            label="Связать с"
            onChange={this.handleChange}
            value={value}
            name="parser-bind-event-input"
          />
        </FlexGrid.Col>
        <Form
          onSubmit={this.linkEvent}
          onSuccess={this.props.forceCloseModal}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <FlexGrid.Col m="14px -40px -25px -40px">
                <StyledTable>
                  <InfiniteScroll
                    height={350}
                    dataLength={dataLength}
                    next={this.loadMore}
                    hasMore={meta.page !== meta.last_page}
                    loader={() => null}
                  >
                    <PlainTable.Table>
                      <PlainTable.Thead>
                        <PlainTable.Tr>
                          <PlainTable.Th />
                          <PlainTable.Th>Мероприятие</PlainTable.Th>
                          <PlainTable.Th>Площадка</PlainTable.Th>
                          <PlainTable.Th>Дата</PlainTable.Th>
                        </PlainTable.Tr>
                      </PlainTable.Thead>
                      <PlainTable.Tbody>
                        {this.state.data.length !== 0 ? (
                          this.state.data.map((item) => {
                            if (item.children) {
                              const childrenWithDatetime = item.children.map((child) => ({
                                ...child,
                                date_time: `${child.date_start} ${child.time_start}`,
                              }));

                              return map(sortBy(childrenWithDatetime, 'date_time'), (child) => {
                                dataLength += 1;

                                return (
                                  <EventRow
                                    key={child.id}
                                    data={{
                                      ...item,
                                      id: child.id,
                                      date_start: child.date_start,
                                      time_start: child.time_start,
                                    }}
                                  />
                                );
                              });
                            }

                            dataLength += 1;

                            return <EventRow key={item.id} data={item} />;
                          })
                        ) : (
                          <StyledTr>
                            <StyledTd>{this.renderPlaceholder()}</StyledTd>
                          </StyledTr>
                        )}
                      </PlainTable.Tbody>
                    </PlainTable.Table>
                  </InfiniteScroll>
                </StyledTable>
              </FlexGrid.Col>
              <Modal.Footer>
                <Button
                  transparent
                  type="button"
                  onClick={this.props.closeModal}
                  data-selenium="parser-bind-cancel"
                >
                  Отмена
                </Button>
                <Button disabled={pristine || invalid} data-selenium="parser-bind-submit">
                  Связать
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modalData: state.modal.data,
});

const mapDispatchToProps = {
  linkParserWithEvent,
  linkParserViewsers,
  clearData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkEventModal));
