import { connect } from 'react-redux';
import SchemeContext from 'components/Pages/Pool/SchemePoolModal/SchemeContext';
import { openSchemeContext, closeScheme, closeSchemeContext } from 'entities/pool/scheme';
import { createContext } from 'entities/call-context';
import { openModal } from 'entities/modal';

const mapStateToProps = (state) => ({
  contextIsVisible: state.poolScheme.contextIsVisible,
});
const mapDispatchToProps = {
  openSchemeContext,
  closeSchemeContext,
  closeScheme,
  createContext,
  openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchemeContext);
