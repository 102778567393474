import Button from 'components/Button';
import { LangForm } from 'components/Form';
import { LangSwitcher } from 'components/FormControls';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
import Spinner from 'components/Spinner';
import Tabs from 'components/Tabs';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'next-i18next';
import React, { useState, useRef, useEffect } from 'react';
import { FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { selectAccount } from 'entities/account/reducer';
import { EVENTS } from 'shared/constants/EVENTS';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Emmiter from 'shared/services/Emmiter';
import { ModalFunctions } from '../../../../../interfaces/modal';
import CloseIcon from '../../../../../static/icons/close.svg';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import { LandingEvent } from '../../types/Event';
import LandingsEventInfo from './LandingsEventInfo';
import LandingsEventPlaces from './LandingsEventPlaces';
import LandingsEventPrices from './LandingsEventPrices';
import LandingsEventWidget from './LandingsEventWidget';
import Styled from './styled';

interface FormProps {
  title: string;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  onChangeLng: any;
  showDeleteModal: () => void;
  isMobile: boolean;
  isDesktop: boolean;
  isEdit?: boolean;
  isLoading?: boolean;
  lng?: string;
  initialValues?: any;
  children?: any;
  event?: LandingEvent;
  onSuccess: (data: any) => any;
  tabPoint?: number;
  hasActiveDates: boolean;
}

const LandingsEventForm: React.FC<FormProps> = (props) => {
  const {
    children,
    title,
    closeModal,
    forceCloseModal,
    isMobile,
    isEdit,
    onChangeLng,
    isLoading = false,
    showDeleteModal,
    lng,
    isDesktop,
    event,
    tabPoint,
    hasActiveDates,
    ...rest
  } = props;

  const [tab, setTab] = useState(1);

  const currentValues = useRef({});
  let form = null;
  const account = useSelector(selectAccount);
  const { t } = useTranslation();

  const goNext = () => {
    setTab(tab + 1);
  };

  const goBack = () => {
    setTab(tab - 1);
  };

  useEffect(() => {
    Emmiter.addListener(EVENTS.GO_NEXT_STEP, goNext);

    return () => {
      Emmiter.removeListener(EVENTS.GO_NEXT_STEP, goNext);
    };
  });
  useEffect(() => {
    if (tabPoint) {
      goNext();
    }
  }, [tabPoint]);
  return (
    <Styled.Wrapper>
      <Styled.GlobalStyles />

      <LangForm
        {...rest}
        stashLanguages={!isEdit}
        initialValues={{
          updateQna: false,
          ...rest.initialValues,
          ...currentValues.current[lng],
          company_id: account.company.id,
        }}
        mutators={{
          ...arrayMutators,
        }}
        onChangeLng={onChangeLng}
        render={({ handleSubmit, form: finalForm, values, switcher, i18n }) => {
          form = finalForm;

          const TABS = [
            {
              text: t('landings:events.information_tab'),
              key: 1,
            },
            {
              text: 'Места',
              key: 2,
              disabled: !event || !hasActiveDates || i18n.language !== LANGUAGES.RU,
              ...(!hasActiveDates && { tooltip: 'Настройка невозможна, все даты мероприятия прошли' }),
            },
            {
              text: 'Наценка',
              key: 3,
              disabled: !event || !hasActiveDates || i18n.language !== LANGUAGES.RU,
              ...(!hasActiveDates && { tooltip: 'Настройка невозможна, все даты мероприятия прошли' }),
            },
            {
              text: 'Виджет',
              key: 4,
              disabled: !event || !hasActiveDates || i18n.language !== LANGUAGES.RU,
              ...(!hasActiveDates && { tooltip: 'Настройка невозможна, все даты мероприятия прошли' }),
            },
          ];

          const changeTab = (value: number) => {
            setTab(value);
          };

          return (
            <>
              <Modal.FullSizeContainer>
                <StepModal.Header>
                  <StepModal.WithLanguage>
                    <StepModal.Title>{t(title)}</StepModal.Title>
                    {isDesktop && (
                      <LangSwitcher
                        isDisabled={(tab === 2 || tab === 3) && 'Необходимо вернуться на предыдущий таб'}
                      />
                    )}
                  </StepModal.WithLanguage>
                  <StepModal.Tabs>
                    <Tabs
                      alignCenter={!isMobile}
                      scrollToSelected={false}
                      tabs={TABS}
                      onSelect={changeTab}
                      selectedTab={tab}
                    />
                  </StepModal.Tabs>
                </StepModal.Header>
                <Modal.FullSizeContent>
                  {tab === 1 && (
                    <form onSubmit={handleSubmit}>
                      <FormSpy
                        subscription={{ values: true }}
                        onChange={(changeProps: any) => {
                          currentValues.current[i18n.language] = changeProps.values[i18n.language];
                        }}
                      />
                      <Modal.Container>
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          <LandingsEventInfo
                            t={t}
                            switcher={switcher}
                            values={values}
                            i18n={i18n}
                            isEdit={isEdit}
                            form={form}
                            initialValue={rest.initialValues}
                          />
                        )}
                      </Modal.Container>
                      {isEdit ? (
                        <Modal.Footer fullSize contentWidth="800px">
                          <Button
                            dangerTransparent
                            onlyIcon={isMobile}
                            type="button"
                            onClick={showDeleteModal}
                          >
                            {isMobile ? <TrashIcon /> : t('forms:delete')}
                          </Button>
                          <Button type="button" transparent onClick={closeModal}>
                            {t('forms:cancel')}
                          </Button>
                          <Button type="submit">
                            {i18n.language !== LANGUAGES.RU || !hasActiveDates
                              ? t('forms:save')
                              : t('forms:continue')}
                          </Button>
                        </Modal.Footer>
                      ) : (
                        <Modal.Footer justify fullSize contentWidth="800px">
                          <Button type="button" transparent onlyIcon={isMobile} onClick={closeModal}>
                            {isMobile ? <CloseIcon /> : t('forms:cancel')}
                          </Button>
                          <Button
                            type="submit"
                            onClick={() => {
                              form.change('action', 'create');
                            }}
                          >
                            {t('forms:continue')}
                          </Button>
                        </Modal.Footer>
                      )}
                    </form>
                  )}
                  {tab === 2 && <LandingsEventPlaces event={event} goNext={goNext} goBack={goBack} />}
                  {tab === 3 && <LandingsEventPrices event={event} goNext={goNext} goBack={goBack} />}
                  {tab === 4 && (
                    <LandingsEventWidget event={event}>
                      <Modal.Footer fullSize justify>
                        <Button transparent type="button" onClick={goBack}>
                          Назад
                        </Button>
                        <Button type="button" onClick={forceCloseModal} data-selenium="button-continue">
                          Готово
                        </Button>
                      </Modal.Footer>
                    </LandingsEventWidget>
                  )}
                </Modal.FullSizeContent>
              </Modal.FullSizeContainer>
            </>
          );
        }}
      />
    </Styled.Wrapper>
  );
};

LandingsEventForm.defaultProps = {
  lng: 'ru',
};

export default withCheckIsMobile(LandingsEventForm);
