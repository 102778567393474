import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface SearchProps {
  switchToTablet?: boolean;
  secondary?: boolean;
}

const Search = styled.div<SearchProps>`
  display: flex;
  margin-left: ${({ switchToTablet }) => switchToTablet && 'auto'};

  @media (max-width: ${BREAKPOINTS.XL}) {
    margin-left: auto;
  }
`;

interface WrapperProps {
  mobileFluid?: boolean;
  isOpen?: boolean;
  secondary?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.MD}) {
    ${({ mobileFluid, isOpen, secondary }) =>
      mobileFluid &&
      secondary &&
      css`
        z-index: 55;
        position: absolute;
        top: 50%;
        left: 16px;
        right: 16px;
        display: ${isOpen ? 'flex' : 'none'};
        width: auto;
        max-width: 100%;
        transform: translateY(-50%);

        & > div {
          width: 100%;
        }
      `}

    ${({ mobileFluid, secondary, isOpen }) =>
      mobileFluid &&
      !secondary &&
      css`
        position: absolute;
        top: 0;
        left: 0;
        width: ${isOpen ? '100%' : 0};
        z-index: 2;

        & > div {
          width: 100%;
        }
      `}
  }
`;

const Close = styled.button`
  position: absolute;
  top: 50%;
  right: 8px;
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  transform: translateY(-50%);
  outline: none;
  cursor: pointer;

  & > svg {
    fill: #999;
    transition: 0.2s ease-in-out;
  }

  &:hover > svg {
    fill: #000;
  }
`;

interface ContainerProps {
  isOpen?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  width: ${(props) => (props.isOpen ? '100%' : 0)};
  overflow: ${(props) => (props.isOpen ? 'visible' : 'hidden')};

  & input,
  & input:focus:not(:read-only) {
    padding-right: 28px !important;
  }
`;

const Button = styled.div`
  height: 40px;

  & svg {
    width: 14px;
    height: 14px;
  }
`;

interface OverlayProps {
  isOpen?: boolean;
}

const Overlay = styled.div<OverlayProps>`
  display: none;

  @media (max-width: ${BREAKPOINTS.MD}) {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    display: ${({ isOpen }) => isOpen && 'block'};
    width: 100%;
    height: 100%;
    background-color: #fafafa;
  }
`;

export default {
  Search,
  Wrapper,
  Container,
  Close,
  Button,
  Overlay,
};
