import styled, { createGlobalStyle } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export default {
  GlobalStyles: createGlobalStyle`
    .modal__footer {
      margin: 48px 0 0 0;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      .modal__body {
        padding: 0;
      }
    }
  `,

  LeftAlignedButton: styled.div`
    margin-right: auto;
    width: 100%;
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,

  Title: styled.div`
    font-size: 20px;
    color: #000;
    word-break: break-all;
    margin-bottom: 24px;
  `,

  Fields: styled.div`
    display: flex;
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: 32px;
    }
  `,
};
