import { useUnit } from 'effector-react';
import React from 'react';
import { Modal, ModalClose } from 'shared/ui/modal';
import PlaceForm from '../../form';
import { placeCreateModal } from '../model';
import Styled from './styles';

export const PlaceCreateModal = () => {
  const loading = useUnit(placeCreateModal.$loading);

  const handleSubmit = useUnit(placeCreateModal.formSubmitted);
  return (
    <Modal toggler={placeCreateModal.toggler}>
      {({ closeModal }) => (
        <Styled.Body className="modal__body">
          <ModalClose onClick={closeModal} />
          <PlaceForm
            initialValues={{
              how_to_get: undefined,
            }}
            title="references:places.create_title"
            onSubmit={handleSubmit}
            closeModal={closeModal}
            stashLanguages
            loading={loading}
          />
        </Styled.Body>
      )}
    </Modal>
  );
};
