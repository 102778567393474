import DropdownComponent from 'components/Dropdown';
import React from 'react';
import { connect } from 'react-redux';
import { openModal, openRouterModal, changeModalState } from 'entities/modal';
import { ModalFunctions } from '../../../../interfaces/modal';
import MODAL_STATES from '../../constants/MODAL_STATES';
import MODALS from '../../constants/MODALS';

interface DropdownProps {
  isLarge?: boolean;
  dataSelenium?: string;
  modalType: string;
  options: any[];
  data: any;
  openModal: ModalFunctions['openModal'];
  deleteMethod: any;
  openRouterModal: ModalFunctions['openRouterModal'];
  copyMethod: (data: number) => void;
  openEffectorModal: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  data,
  modalType,
  deleteMethod,
  copyMethod,
  openEffectorModal,
  ...rest
}) => {
  const handleChange = (value, _, event) => {
    event.stopPropagation();
    switch (value) {
      case MODAL_STATES.DELETE:
        return rest.openModal(MODALS.CONFIRM_MODAL, {
          onSubmit: () => deleteMethod(data),
          title: 'Удаление',
          text: `Вы действительно хотите удалить данную позицию?`,
        });

      case MODAL_STATES.DELETE_MARKUP:
        return rest.openModal(MODALS.DELETE_MARKUP, data);

      case MODAL_STATES.COPY:
        return copyMethod(data);

      case MODAL_STATES.EDIT_REFERENCE_QNA:
        return rest.openModal(MODALS.REFERENCE_QnA, {
          state: data,
          data: data,
          id: data?.id,
          modalData: {
            confirmClose: true,
          },
        });

      case MODAL_STATES.EDIT:
        return rest.openRouterModal({ modalType, data, modalState: MODAL_STATES.EDIT });

      case MODAL_STATES.EDIT_EFFECTOR_MODAL: {
        return openEffectorModal(data);
      }

      default:
        return null;
    }
  };

  return <DropdownComponent options={options} onClick={handleChange} data={data} {...rest} />;
};

const mapDispatchToProps = {
  openModal,
  openRouterModal,
  changeModalState,
};

export const CustomDropdown = connect(null, mapDispatchToProps)(Dropdown);
