import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import { EVENTS } from 'shared/constants/EVENTS';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import useAttachments from 'shared/helpers/attachments';
import { isPasteDate } from 'shared/helpers/date';
import Emmiter from 'shared/services/Emmiter';
import attachmentsSource from 'shared/sources/attachments';
import landingEventSources from 'shared/sources/landings/landingsEvents';
import {
  buildEventAttachments,
  getDifferenceInContentGroups,
  getTranslations,
} from '../../helpers/landingHelpers';
import withLandingData from '../../helpers/withLandingData';
import EventsForm from './EventsForm';
import api from 'services/api';
import { updateAttachments } from 'shared/lib/attachments';
import isEmpty from 'lodash/isEmpty';
import config from 'config/config';
import { getCookie } from 'shared/lib/cookie';
import COOKIES from 'shared/constants/COOKIES';

interface EventsUpdateProps {
  closeModal: () => void;
  forceCloseModal: () => void;
  data: any;
  lng: string;
  showDeleteModal: () => void;
  onChangeLng: () => void;
  updateTranslations: (data: any) => void;
  updateData: (data: any) => void;
  onUpdateLandingEvents: (data: any) => any;
  tabPoint: number;
}

const updateAttachmentsInfo = async ({
  id,
  formData,
  formLang,
}: {
  id: number;
  formData: any;
  formLang: string;
}) => {
  const person = await api.get(landingEventSources.detail(id)).then((res) => res.data);
  const infoAttachments = updateAttachments(person, { lng: formLang, [formLang]: formData });

  await Promise.all(
    infoAttachments
      .filter((attachment) => !isEmpty(attachment.info))
      .map(async (attachment) => {
        const route = `${config.COMMON_API_URL}/admin/v1/${getCookie(COOKIES.COMPANY)}/landings/events`;
        if (attachment.info?.is_create) {
          const response = await api.post(
            `${route}/${id}/attachments/${attachment.attachment_type_id}/info`,
            {
              ...attachment.info,
              language_code: attachment.language_code,
            },
          );

          return response;
        }

        const response = await api.patch(
          `${route}/${id}/attachments/${attachment.attachment_type_id}/info/${attachment.language_code}`,
          attachment.info,
        );

        return response;
      }),
  );
};

const EventsUpdate: React.FC<EventsUpdateProps> = (props) => {
  const {
    data,
    onChangeLng,
    closeModal,
    forceCloseModal,
    showDeleteModal,
    updateTranslations,
    updateData,
    onUpdateLandingEvents,
    tabPoint,
    lng,
  } = props;

  const hasActiveDates = data.event?.dates.filter((child) => !isPasteDate(child.finished_at)).length > 0;

  const cover = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Cover;
  });
  const gallery = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Gallery;
  });
  const preview = data.attachments?.find((attachement) => {
    return attachement.attachment_type.code === AttachmentType.Preview;
  });
  const previewInfo = preview?.info?.find((i) => i.language_code === lng);
  const coverInfo = cover?.info?.find((i) => i.language_code === lng);

  const info = getTranslations(data.info, lng);
  const initialValues = {
    lng,
    id: data.id,
    contentGroups:
      data.content_groups?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
    event: {
      value: data.event?.id,
      label: data.event?.title,
    },
    allow_indexing: data.allow_indexing,
    is_top: data.is_top,
    is_cover: data.is_cover,
    affiche_title: info?.affiche_title,
    annotation: info?.annotation,
    description: info?.description,
    hint: info?.hint,
    title: info?.title,
    cover: cover?.data,
    preview: preview?.data,
    gallery: gallery?.data,
    ...(coverInfo && { cover_title: coverInfo.title, cover_alt: coverInfo.alt }),
    ...(previewInfo && { preview_title: previewInfo.title, preview_alt: previewInfo.alt }),
    widget_id: data.widget_id,
    qna:
      data.qna?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
  };

  const deleteEventsAttachments = (formData, attachementID) =>
    attachmentsSource.deleteEventsAttachments(formData, attachementID);

  const createEventsAttachments = (formData, attachementID) => {
    return attachmentsSource.createEventsAttachments(formData, attachementID);
  };

  const editEventsAttachments = (formData, attachementID, formAttachment) => {
    return attachmentsSource.editEventsAttachments(formData, attachementID, formAttachment);
  };

  const { buildPreviewAttachment, buildCoverAttachment, buildGalleryAttachment } = useAttachments({
    deleteMetod: deleteEventsAttachments,
    createMetod: createEventsAttachments,
    editMetod: editEventsAttachments,
    data,
  });

  const handleUpdate = async (submitData) => {
    const formLang = submitData.lng;
    const formData = {
      ...submitData[lng],
      hint: get(submitData[lng], 'hint') || '',
    };

    const attachments = await buildEventAttachments(formData);

    await buildCoverAttachment(formData, attachments);
    await buildPreviewAttachment(formData, attachments);
    await buildGalleryAttachment(formData, attachments);
    await updateAttachmentsInfo({ id: data.id, formData, formLang });

    if (formLang === LANGUAGES.RU && isEqual(formData, initialValues)) {
      return {};
    }

    const translations = getTranslations(data.info, formLang);

    if (translations) {
      const newTranslations = await landingEventSources.updateTranslation(data.id, formLang, formData);

      updateTranslations(newTranslations);
    } else {
      const newTranslations = await landingEventSources.createTranslation(data.id, {
        ...formData,
        language_code: formLang,
      });

      updateTranslations(newTranslations);
    }
    if (formLang !== LANGUAGES.RU) return {};

    const { contentGroupsforDelete, contentGroupsforUpdate, qnaforUpdate, qnaforDelete } =
      getDifferenceInContentGroups({
        initialData: initialValues,
        formData,
      });

    if (contentGroupsforDelete?.length) {
      landingEventSources.deleteContentGroups(data.id, contentGroupsforDelete);
    }

    if (contentGroupsforUpdate.length) {
      const groupsResponse: any = await landingEventSources
        .createContentGroups(data.id, contentGroupsforUpdate)
        .catch((error) => error);

      if (groupsResponse.response?.data?.detail === 'Already exists') {
        return {
          error: {
            response: {
              data: {
                error: ['Данная персона с таким тегом существует'],
              },
            },
          },
        };
      }
    }

    if (qnaforUpdate?.length) {
      landingEventSources.createQnA(data.id, qnaforUpdate);
    }
    if (qnaforDelete?.length) {
      landingEventSources.deleteQnA(data.id, qnaforDelete);
    }

    const updatedEvent = await landingEventSources.updateEvent({
      data: formData,
    });

    if (submitData.submitAction === SUBMIT_ACTIONS.SAVE) {
      forceCloseModal();

      return updatedEvent;
    }

    if (hasActiveDates) {
      Emmiter.emit(EVENTS.GO_NEXT_STEP);
    } else {
      forceCloseModal();
    }
    updateData({
      ...updatedEvent,
      content_groups: formData.contentGroups.map((item) => ({
        id: item.value,
        title: item.label,
      })),
      qna: formData.qna
        ? formData.qna.map((item) => ({
            id: item.value,
            title: item.label,
          }))
        : [],
    });

    return formData;
  };

  return (
    <EventsForm
      tabPoint={tabPoint}
      key="edit"
      title="landings:events.edit_title"
      onSubmit={handleUpdate}
      isEdit
      onSuccess={onUpdateLandingEvents}
      closeModal={closeModal}
      lng={lng}
      initialValues={initialValues}
      onChangeLng={onChangeLng}
      showDeleteModal={showDeleteModal}
      event={data}
      hasActiveDates={hasActiveDates}
      forceCloseModal={forceCloseModal}
    />
  );
};

export default withLandingData(EventsUpdate, {
  translation: 'events',
  sources: landingEventSources,
  emitUpdateTableAfterSave: true,
});
