import React from 'react';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import landingEventSources from 'shared/sources/landings/landingsEvents';
import attachmentsSource from 'shared/sources/attachments';
import EventsForm from './EventsForm';
import { buildEventAttachments } from '../../helpers/landingHelpers';

interface EventsCreateProps {
  lng: string;
  closeModal: () => void;
  onChangeLng: () => void;
  setFormData: (data: any, tab: number) => void;
}

const EventsCreate: React.FC<EventsCreateProps> = (props) => {
  const { onChangeLng, closeModal, lng, setFormData } = props;
  const tab = 2;

  const handleCreate = async (formData) => {
    const { ru: eventData } = formData;
    const attachments = await buildEventAttachments(eventData);

    const createdEvent = await landingEventSources.createEvent({
      data: eventData,
    });

    await attachmentsSource.createEventsAttachments(createdEvent.id, attachments);

    await landingEventSources.createTranslation(createdEvent.id, {
      ...eventData,
      language_code: LANGUAGES.RU,
    });

    await landingEventSources.createContentGroups(
      createdEvent.id,
      eventData.contentGroups.map((i) => i.value),
    );

    if (eventData.qna) {
      await landingEventSources.createQnA(
        createdEvent.id,
        eventData.qna.map((i) => i.value),
      );
    }

    const newEvent = await landingEventSources.getEvent(createdEvent.id);

    setFormData(newEvent, tab);

    return newEvent;
  };

  return (
    <EventsForm
      key="create"
      title="landings:events.create_title"
      lng={lng}
      onSubmit={handleCreate}
      closeModal={closeModal}
      initialValues={{
        initialQna: [],
        allow_indexing: true,
        item_create: true,
      }}
      onChangeLng={onChangeLng}
    />
  );
};

export default EventsCreate;
