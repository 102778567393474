import styled from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Calendar: styled.div`
    display: none;

    @media ${MEDIA_QUERIES.MOBILE} {
      display: block;
    }
  `,

  Close: styled.div`
    position: absolute;
    top: 0;
    right: 0;
  `,
};
