import React, { useEffect, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { required } from 'shared/helpers/validations';
import Form from '../../../Form';
import {
  ColorPicker,
  InputWithSelect,
  Input,
  Checkbox,
  FilePreviewInput,
  NumberInput,
  Select,
} from '../../../FormControls';
import { companySources } from 'entities/company';
import {
  renderEmails,
  renderPayments,
  renderLanguages,
  renderLanguagesWithSelectAll,
  renderCurrencyForMultiSelect,
} from 'shared/helpers/optionsRenderers';
import Styled from './styles';
import ConstructorFooter from './ConstructorFooter';
import LANDINGS_PAYMENTS_OPTIONS from 'shared/constants/LANDINGS_PAYMENTS_OPTIONS';
import landingsSitesSource from 'shared/sources/landings/landingsSites';
import { OptionWithCheckboxAndIcon } from '../../../FormControls/InputWithSelect';
import { currencySources } from 'entities/currency';
import CONSTRUCTOR_FIELDS from 'shared/constants/CONSTRUCTOR_FIELDS';
import { availableCurrencyLanguages } from 'shared/helpers/currency';
import { LANGUAGES } from 'shared/constants/LANGUAGES';

let customOnChange;

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
  lng: string;
}

const FinishStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack, lng }) => {
  const [previewState, updatePreviewState] = useState<any>({});
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  useEffect(() => {
    currencySources.getCurrencies().then((response) => {
      const rubleCurrency = response.find((currency) => currency.char_code === 'RUB');
      if (rubleCurrency) {
        setSelectedCurrency([
          {
            label: rubleCurrency.name,
            value: rubleCurrency.char_code,
            withoutIcon: true,
          },
        ]);
      }
    });
  }, []);

  const onChange = (value) => {
    customOnChange(100 - value);
  };

  const subscription = (values) => {
    const {
      ui_config_bg_opacity: currentOpacity,
      ui_config_bg_color: currentBgColor,
      ui_config_twisting: twisting,
    } = values;

    updatePreviewState({
      backgroundColor: currentBgColor,
      opacity: `${currentOpacity}%`,
      twisting,
    });
  };

  const disabledByLanguage = lng !== LANGUAGES.RU;

  return (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Завершение</Styled.Title>
      </Styled.Header>
      <Styled.Content>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            ...data,
            currencies: !data.currencies?.length ? selectedCurrency : data.currencies,
          }}
          render={({ handleSubmit, form, values: formValues }) => {
            return (
              <>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={(state) => {
                    const { values } = state;

                    subscription(values);
                  }}
                />

                <form onSubmit={handleSubmit}>
                  {!disabledByLanguage && (
                    <>
                      <Styled.FormRow>
                        <Styled.FormDescription>Цвета</Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Styled.Column>
                              <Field name="ui_config_primary_color" validate={required}>
                                {({ input, meta }) => (
                                  <ColorPicker label="Основной цвет" input={input} meta={meta} />
                                )}
                              </Field>
                            </Styled.Column>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Styled.Column>
                              <Field name="ui_config_secondary_color" validate={required}>
                                {({ input, meta }) => (
                                  <ColorPicker label="Доп цвет" input={input} meta={meta} />
                                )}
                              </Field>
                            </Styled.Column>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>

                      <Styled.FormRow>
                        <Styled.FormDescription>
                          Фон сайта
                          <Styled.FormSmallDescription>Настройка цвета фона</Styled.FormSmallDescription>
                        </Styled.FormDescription>
                        <Styled.FormControls large>
                          <Styled.FormControl>
                            <Styled.Column>
                              <Field name="ui_config_bg_color" validate={required}>
                                {({ input, meta }) => (
                                  <ColorPicker label="Цвет фона" input={input} meta={meta} />
                                )}
                              </Field>
                            </Styled.Column>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Styled.Column>
                              <Field name="ui_config_bg_gradient" type="checkbox">
                                {({ input, meta }) => (
                                  <Checkbox subText="Градиент" variant="round" meta={meta} {...input} />
                                )}
                              </Field>
                            </Styled.Column>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Styled.Column>
                              <Field name="ui_config_bg_pattern">
                                {({ input, meta }) => (
                                  <FilePreviewInput
                                    {...input}
                                    meta={meta}
                                    settings={previewState}
                                    // eslint-disable-next-line max-len
                                    description="Рекомендуемый размер паттерна 200х200px (1:1), не более 1MB в формате PNG с прозрачностью"
                                  >
                                    <Styled.FormControl>
                                      <Styled.SmallInput>
                                        <Field name="ui_config_bg_opacity">
                                          {({ input: opacityInput, meta: opacityMeta }) => {
                                            customOnChange = opacityInput.onChange;
                                            return (
                                              <NumberInput
                                                label="Прозрачность, %"
                                                type="number"
                                                {...opacityInput}
                                                value={100 - opacityInput.value}
                                                onChange={onChange}
                                                max={100}
                                                meta={opacityMeta}
                                              />
                                            );
                                          }}
                                        </Field>
                                      </Styled.SmallInput>
                                    </Styled.FormControl>
                                  </FilePreviewInput>
                                )}
                              </Field>
                            </Styled.Column>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>

                      <Styled.FormRow>
                        <Styled.FormDescription>
                          Скругление углов
                          <Styled.FormSmallDescription>
                            Углы кнопок, карточек, картинок
                          </Styled.FormSmallDescription>
                        </Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Styled.Column>
                              <Styled.SmallInput>
                                <Field name="ui_config_twisting" validate={required}>
                                  {({ input, meta }) => (
                                    <NumberInput
                                      label="Скругление углов"
                                      value={input.value}
                                      max={16}
                                      {...input}
                                      meta={meta}
                                    />
                                  )}
                                </Field>
                                <Styled.TwistingContainer>
                                  <Styled.TwistingPreview twisting={previewState.twisting} />
                                </Styled.TwistingContainer>
                              </Styled.SmallInput>
                            </Styled.Column>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>

                      <Styled.FormRow>
                        <Styled.FormDescription>Инфо</Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Field name="site_acquiring_config">
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  label="Эквайринг"
                                  route={companySources}
                                  routeName="payments"
                                  optionsRenderer={renderPayments}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </Styled.FormControl>
                          {formValues.site_acquiring_config && (
                            <Styled.FormControl>
                              <Field name="site_acquiring_second_config">
                                {({ input, meta }) => (
                                  <InputWithSelect
                                    isAsync
                                    label="Эквайринг 2"
                                    route={companySources}
                                    routeName="payments"
                                    optionsRenderer={renderPayments}
                                    meta={meta}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </Styled.FormControl>
                          )}
                          <Styled.FormControl>
                            <Field name="email_config" validate={required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  label="Email"
                                  route={companySources}
                                  routeName="emailConfig"
                                  optionsRenderer={renderEmails}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Field name="gtm_id">
                              {({ input, meta }) => <Input label="GTM ID" meta={meta} {...input} />}
                            </Field>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Field name="code_id">
                              {({ input, meta }) => (
                                <Input label="ID ключей reCAPTCHA" meta={meta} {...input} />
                              )}
                            </Field>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Field name="site_payment_config">
                              {({ input, meta }) => (
                                <Select
                                  label="Способ оплаты"
                                  options={LANDINGS_PAYMENTS_OPTIONS}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>

                      <Styled.FormRow>
                        <Styled.FormDescription>Положение заголовков</Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Field name="ui_config_titles">
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isClearable={false}
                                  label="Выберите вариант"
                                  options={[CONSTRUCTOR_FIELDS.titles[1], CONSTRUCTOR_FIELDS.titles[2]]}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>

                      <Styled.FormRow>
                        <Styled.FormDescription>Подложка</Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Field name="ui_config_layout">
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isClearable={false}
                                  label="Выберите вариант"
                                  options={[CONSTRUCTOR_FIELDS.layout[1], CONSTRUCTOR_FIELDS.layout[2]]}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Styled.Column>
                              <Field name="ui_config_container_color">
                                {({ input, meta }) => (
                                  <ColorPicker label="Цвет подложки" input={input} meta={meta} />
                                )}
                              </Field>
                            </Styled.Column>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Styled.Column>
                              <Field name="ui_config_container_text_color">
                                {({ input, meta }) => (
                                  <ColorPicker label="Цвет текста" input={input} meta={meta} />
                                )}
                              </Field>
                            </Styled.Column>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>

                      {/* <Styled.FormRow>
                        <Styled.FormDescription>Группировка афиши</Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Field name="ui_config_index_top_poster_grouping" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Группировать топовые периодические мероприятия
                                </Checkbox>
                              )}
                            </Field>
                            <Styled.FormSmallDescription>
                              Группировка даты мероприятий на главной странице в топ афише / пример: 12 апр–23
                              авг
                            </Styled.FormSmallDescription>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Field name="ui_config_index_poster_grouping" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Группировать периодические мероприятия
                                </Checkbox>
                              )}
                            </Field>
                            <Styled.FormSmallDescription>
                              Группировка даты мероприятий на главной странице в афише / пример: 12 апр–23 авг
                            </Styled.FormSmallDescription>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow> */}

                      <Styled.FormRow>
                        <Styled.FormDescription>Репертуар</Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Field name="ui_config_repertoire_numbering" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Показывать алфавитные указатели
                                </Checkbox>
                              )}
                            </Field>
                            <Styled.FormSmallDescription>
                              Показ буквенных и числовых символов в списке репертуара
                            </Styled.FormSmallDescription>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>
                      <Styled.FormRow>
                        <Styled.FormDescription>Цены</Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Field name="price_ranking" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Включить показ цен в афише
                                </Checkbox>
                              )}
                            </Field>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>
                      <Styled.FormRow>
                        <Styled.FormDescription>
                          Мультивалютность и мультиязычность
                          <Styled.FormSmallDescription>
                            Выбор валюты и языка доступных на сайте и сопоставление
                          </Styled.FormSmallDescription>
                        </Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Field name="ui_config_show_translation" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Включить мультиязычность
                                </Checkbox>
                              )}
                            </Field>
                          </Styled.FormControl>
                          {formValues.ui_config_show_translation && (
                            <>
                              <Styled.FormControl>
                                <Field name="languages">
                                  {({ input, meta }) => (
                                    <InputWithSelect
                                      isAsync
                                      isMulti
                                      withoutPagination
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      controlShouldRenderValue={false}
                                      label="Языки"
                                      route={landingsSitesSource}
                                      routeName="languages"
                                      optionsRenderer={renderLanguagesWithSelectAll}
                                      withCounter
                                      meta={meta}
                                      {...input}
                                      components={{
                                        Option: OptionWithCheckboxAndIcon,
                                      }}
                                    />
                                  )}
                                </Field>
                              </Styled.FormControl>
                              <Styled.FormControl>
                                <Field name="main_lang">
                                  {({ input, meta }) => (
                                    <InputWithSelect
                                      isAsync
                                      withoutPagination
                                      label="Основной язык"
                                      route={landingsSitesSource}
                                      routeName="languages"
                                      optionsRenderer={renderLanguages}
                                      meta={meta}
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </Styled.FormControl>
                            </>
                          )}
                          <Styled.FormControl>
                            <Field name="ui_config_show_currencies" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Включить мультивалютность
                                </Checkbox>
                              )}
                            </Field>
                          </Styled.FormControl>
                          {formValues.ui_config_show_currencies && (
                            <Styled.FormControl>
                              <Field name="currencies">
                                {({ input, meta }) => (
                                  <InputWithSelect
                                    isAsync
                                    isMulti
                                    withoutPagination
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    label="Валюта"
                                    route={currencySources}
                                    routeName="root"
                                    optionsRenderer={renderCurrencyForMultiSelect}
                                    withCounter
                                    meta={meta}
                                    {...input}
                                    components={{
                                      Option: OptionWithCheckboxAndIcon,
                                    }}
                                  />
                                )}
                              </Field>
                            </Styled.FormControl>
                          )}
                          {formValues.ui_config_show_translation && formValues.ui_config_show_currencies && (
                            <>
                              <Styled.FormControl>Сопоставление валюты и языков</Styled.FormControl>
                              {formValues.currencies &&
                                formValues.currencies.map((currency) => (
                                  <Styled.FormControl>
                                    <Field name={`language_currency.${currency.value.toLowerCase()}`}>
                                      {({ input, meta }) => (
                                        <InputWithSelect
                                          isMulti
                                          label={currency.label}
                                          options={availableCurrencyLanguages(formValues)}
                                          meta={meta}
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                  </Styled.FormControl>
                                ))}
                            </>
                          )}
                        </Styled.FormControls>
                      </Styled.FormRow>

                      <Styled.FormRow>
                        <Styled.FormDescription>
                          Афиша категории и залы
                          <Styled.FormSmallDescription>
                            Настройка показа категорий и залов в афише
                          </Styled.FormSmallDescription>
                        </Styled.FormDescription>
                        <Styled.FormControls>
                          <Styled.FormControl>
                            <Field name="ui_config_event_show_categories" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Показ категории
                                </Checkbox>
                              )}
                            </Field>
                            <Styled.FormSmallDescription>
                              Показывать категорию мероприятий в афише
                            </Styled.FormSmallDescription>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Field name="ui_config_event_show_halls" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Показ зала
                                </Checkbox>
                              )}
                            </Field>
                            <Styled.FormSmallDescription>
                              Показывать залы площадок в афише мероприятий
                            </Styled.FormSmallDescription>
                          </Styled.FormControl>
                          <Styled.FormControl>
                            <Field name="ui_config_event_show_cities" type="checkbox">
                              {({ input, meta }) => (
                                <Checkbox variant="round" meta={meta} {...input}>
                                  Показ города
                                </Checkbox>
                              )}
                            </Field>
                            <Styled.FormSmallDescription>
                              Показывать города в афише мероприятий
                            </Styled.FormSmallDescription>
                          </Styled.FormControl>
                        </Styled.FormControls>
                      </Styled.FormRow>
                    </>
                  )}
                  {!disabledByLanguage && (
                    <Styled.FormRow>
                      <Styled.FormDescription>Формат времени</Styled.FormDescription>
                      <Styled.FormControls>
                        <Styled.FormControl>
                          <Field name="ui_config_use_am_pm" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Включить am/pm формат времени
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.FormControl>
                      </Styled.FormControls>
                    </Styled.FormRow>
                  )}

                  {!disabledByLanguage && (
                    <Styled.FormRow>
                      <Styled.FormDescription>Города</Styled.FormDescription>
                      <Styled.FormControls>
                        <Styled.FormControl>
                          <Field name="ui_config_split_by_cities" type="checkbox">
                            {({ input, meta }) => (
                              <Checkbox variant="round" meta={meta} {...input}>
                                Разбивать контент по городам
                              </Checkbox>
                            )}
                          </Field>
                        </Styled.FormControl>
                      </Styled.FormControls>
                    </Styled.FormRow>
                  )}

                  <ConstructorFooter goBack={goBack} form={form} updateData={updateData} withoutNextAction />
                </form>
              </>
            );
          }}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default FinishStep;
