import isArray from 'lodash/isArray';
import React from 'react';
import Styled from './styles';

interface LabelProps {
  withIcon?: boolean;
  disabled?: boolean;
  meta?: any;
  value: any;
  children: any;
}

const hasValue = (value) => {
  if (isArray(value)) {
    return value.length > 0;
  }

  if (Number(value)) {
    return true;
  }

  return Boolean(value);
};

const Label: React.FC<LabelProps> = ({ children, value, meta = {}, withIcon, disabled }) => {
  const isFocused = meta && meta.active;

  return (
    <Styled.Container withIcon={withIcon} active={isFocused || hasValue(value)} className="input-label">
      <Styled.Label isFocused={isFocused} meta={meta} disabled={disabled}>
        {children}
      </Styled.Label>
    </Styled.Container>
  );
};

export default Label;
