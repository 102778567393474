import { createEvent, createStore, sample } from 'effector';
import { Router } from 'next/router';
import * as markupsApi from 'shared/api/markups';

export const pageMounted = createEvent<any>();

export const $query = createStore<Router['query']>({});
export const $markups = createStore([]);
export const $meta = createStore({});
export const $loading = createStore(true);

sample({
  clock: pageMounted,
  target: $query,
});

sample({
  source: { query: $query },
  fn: ({ query }) => query,
  target: markupsApi.markupItemsFx,
});

sample({
  source: $query,
  fn: () => true,
  target: $loading,
});

sample({
  clock: markupsApi.markupItemsFx.done,
  fn: () => false,
  target: $loading,
});

sample({
  clock: markupsApi.markupItemsFx.done,
  fn: ({ result }) => {
    return result.results;
  },
  target: $markups,
});

sample({
  clock: markupsApi.markupItemsFx.done,
  fn: ({ result }) => {
    const { results, ...meta } = result;
    return meta;
  },
  target: $meta,
});
