import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { FILE_TYPES_REGEX } from 'shared/constants/REGEX';
import attachmentsSource from 'shared/sources/attachments';

export const getTranslations = (info, lang) => {
  return info?.find((tranlation) => tranlation.language_code === lang);
};

const findAttachments = (data, attachmentTypes) => {
  const attachments = [];

  if (data.main_image && !isEmpty(data.main_image)) {
    attachments.push({
      data: data.main_image,
      attachment_type_id: attachmentTypes.main_image.id,
      title: data.main_image_title ?? '',
      alt: data.main_image_alt ?? '',
      code: attachmentTypes.main_image.code ?? '',
    });
  }

  if (data.preview && !isEmpty(data.preview)) {
    attachments.push({
      data: data.preview,
      attachment_type_id: attachmentTypes.preview.id,
      title: data.preview_title ?? '',
      alt: data.preview_alt ?? '',
      code: attachmentTypes.preview.code ?? '',
    });
  }

  if (data.cover && !isEmpty(data.cover)) {
    attachments.push({
      data: data.cover,
      attachment_type_id: attachmentTypes.cover.id,
      title: data.cover_title ?? '',
      alt: data.cover_alt ?? '',
      code: attachmentTypes.cover.code ?? '',
    });
  }

  if (data.gallery && data.gallery.length) {
    attachments.push(
      {
        data: data.gallery,
        attachment_type_id: attachmentTypes.gallery.id,
        title: attachmentTypes.gallery.title ?? '',
        alt: attachmentTypes.gallery.code ?? '',
        code: attachmentTypes.gallery.code ?? '',
      },
      {
        data: data.gallery.map((img) => img.replace(FILE_TYPES_REGEX, 'webp')),
        attachment_type_id: attachmentTypes.gallery_webp.id,
        title: attachmentTypes.gallery_webp.title ?? '',
        alt: attachmentTypes.gallery_webp.code ?? '',
        code: attachmentTypes.gallery_webp.code ?? '',
      },
    );
  }

  return attachments;
};

export const buildEventAttachments = async (data) => {
  const attachmentTypes = await attachmentsSource.getAttachmentsTypes('events');
  const attachments = findAttachments(data, attachmentTypes);
  return attachments;
};

export const buildPlacesAttachments = async (data) => {
  const attachmentTypes = await attachmentsSource.getAttachmentsTypes('places');

  const attachments = findAttachments(data, attachmentTypes);

  return attachments;
};

export const buildPersonsAttachments = async (data) => {
  const attachmentTypes = await attachmentsSource.getAttachmentsTypes('persons');

  const attachments = findAttachments(data, attachmentTypes);

  return attachments;
};

export const getDifferenceInContentGroups = ({ initialData, formData }) => {
  const newContentGroupIds = formData.contentGroups.map((i) => i.value);
  const initialContentGroupsIds = initialData.contentGroups?.map((i) => i.value);
  const initialContentGroups = new Set(initialContentGroupsIds);
  const newContentGroups = new Set(newContentGroupIds);
  const contentGroupsforDelete = initialContentGroupsIds.filter((x) => !newContentGroups.has(x));
  const contentGroupsforUpdate = newContentGroupIds.filter((x) => !initialContentGroups.has(x));
  const newQnAIds = formData.qna?.map((i) => i.value);
  const initialQnAIds = initialData.qna?.map((i) => i.value);
  const initialQnA = new Set(initialQnAIds);
  const newQnA = new Set(newQnAIds);
  const qnaforDelete = initialQnAIds?.filter((x) => !newQnA.has(x));
  const qnaforUpdate = newQnAIds?.filter((x) => !initialQnA.has(x));

  return {
    contentGroupsforDelete,
    contentGroupsforUpdate,
    qnaforDelete,
    qnaforUpdate,
  };
};

export const isPasteDate = (date: string) => moment(date, 'YYYY-MM-DDTHH:mm:ss').isBefore(moment());
