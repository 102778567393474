import Pagination from 'components/Pagination';
import { PlainTable } from 'components/Table';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { ReferenceLayout } from 'widgets/layouts/reference-layout';
import { TABLE_EVENTS } from 'shared/constants/EVENTS';
import { selectCol } from 'shared/lib/queryFilter';
import Emmiter from 'shared/services/Emmiter';
import qnaSources from 'shared/sources/reference/qna';
import ColSortDown from '../../../../../../static/icons/col-sort-down.svg';
import ColSortUp from '../../../../../../static/icons/col-sort-up.svg';
import { QnAListItem } from './item';

interface ListProps {
  setListParams: (data: any) => any;
}

export const QnAList: React.FC<ListProps> = (props) => {
  const { setListParams } = props;
  const [qnaItems, setQnaItems] = useState({ results: [], meta: [] });
  const router = useRouter();
  useEffect(() => {
    const fetchData = () => {
      qnaSources
        .qnaItems(
          router.query.page,
          router.query.limit,
          router.query.search_string,
          router.query.sort_on,
          router.query.sort_by,
        )
        .then((response) => {
          const { results, ...meta } = response;

          setQnaItems((prevState) => ({
            ...prevState,
            results,
            meta,
          }));
        });
    };
    Emmiter.on(TABLE_EVENTS.UPDATE_TABLE, fetchData);
    fetchData();

    return () => {
      Emmiter.removeListener(TABLE_EVENTS.UPDATE_TABLE, fetchData);
    };
  }, [
    router.query.page,
    router.query.limit,
    router.query.search_string,
    router.query.sort_on,
    router.query.sort_by,
  ]);

  useEffect(() => {
    setListParams({ source: qnaSources });
  }, []);

  const renderIcon = (queryName) => {
    if (router.query.sort_by !== queryName) return <ColSortDown opacity={0.4} />;
    if (router.query.sort_by === queryName && router.query.sort_on === 'asc') return <ColSortUp />;
    if (router.query.sort_by === queryName && router.query.sort_on === 'desc') return <ColSortDown />;
    return null;
  };

  const handleSort = (queryName) => {
    selectCol({ query: queryName, alternateSortQuery: true });
  };

  return (
    <ReferenceLayout.Content>
      <ReferenceLayout.List>
        <PlainTable.Table>
          <PlainTable.Thead>
            <PlainTable.Tr>
              <PlainTable.Th clickable onClick={() => handleSort('title')} width="60%">
                Название {renderIcon('title')}
              </PlainTable.Th>
              <PlainTable.Th clickable onClick={() => handleSort('updated_at')} width="40%">
                Дата изменения {renderIcon('updated_at')}
              </PlainTable.Th>
              <PlainTable.Th> </PlainTable.Th>
            </PlainTable.Tr>
          </PlainTable.Thead>
          <PlainTable.Tbody>
            {qnaItems.results.map((item) => {
              return <QnAListItem key={item.id} data={item} />;
            })}
          </PlainTable.Tbody>
        </PlainTable.Table>
      </ReferenceLayout.List>
      <ReferenceLayout.Nav>
        <Pagination limits={[25, 50, 100, 150]} meta={qnaItems.meta} updateQuery />
      </ReferenceLayout.Nav>
    </ReferenceLayout.Content>
  );
};
