import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Input, InputWithSelect } from 'components/FormControls';
import { LangForm } from 'components/Form';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Styled from './styles';
import landingsTagsSources from 'shared/sources/landings/landingsTags';
import { ModalFunctions } from '../../../../../interfaces/modal';
import withLandingData from '../withLandingData';
import PAGES from 'shared/constants/PAGES';
import LOCALES from 'shared/constants/LOCALES';
import { withTranslation } from '../../../../i18n';
import { required } from 'shared/helpers/validations';
import { selectAccount } from '../../../../entities/account/reducer';
import { renderContentGroups } from 'shared/helpers/optionsRenderers';
import { editTranslationData } from 'shared/helpers/form';
import landingsRedirectSources from 'shared/sources/landings/landingsRedirects';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';

interface ModalProps {
  data: any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  showDeleteModal: () => void;
  modalState?: string;
  t: (value: string, params?: { [key: string]: any }) => string;
  onChangeLng: any;
  updateList: (data: any) => void;
  onUpdate: (data: any) => void;
  create: (data: any) => void;
  update: (data: any) => void;
  isMobile?: boolean;
}

const LandingsRedirectModal: React.FC<ModalProps> = (props) => {
  const { data, onChangeLng, updateList, onUpdate, t, create, update, isMobile, showDeleteModal } = props;
  const account: any = useSelector(selectAccount);
  const isEdit = props.data.id;

  return (
    <Styled.Container>
      <Styled.GlobalStyles />
      <Modal.Title>{t(`landings:redirects.${isEdit ? 'edit' : 'create'}_title`)}</Modal.Title>
      <LangForm
        stashLanguages={!isEdit}
        onChangeLng={onChangeLng}
        onSubmit={isEdit ? update : create}
        onSuccess={(submitData) => {
          if (isEdit) {
            return onUpdate(submitData);
          }
          return updateList(submitData);
        }}
        initialValues={{
          ...data,
          company_id: account.company.id,
          content_groups: data.content_groups
            ? data.content_groups.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            : [],
        }}
        render={({ handleSubmit, i18n }) => (
          <form onSubmit={handleSubmit}>
            <Styled.Fields>
              <Field name={`${i18n.language}.from_path`} validate={required}>
                {({ input, meta }) => (
                  <Input label={t('landings:redirects.from_path')} {...input} meta={meta} />
                )}
              </Field>
              <Field name={`${i18n.language}.to_path`} validate={required}>
                {({ input, meta }) => (
                  <Input label={t('landings:redirects.to_path')} {...input} meta={meta} />
                )}
              </Field>
              <Field name={`${i18n.language}.content_groups`} validate={required}>
                {({ input, meta }) => (
                  <InputWithSelect
                    isAsync
                    isMulti
                    isCreatable
                    label={t('landings:redirects.tags')}
                    searchQueryName="search_string"
                    optionsRenderer={renderContentGroups}
                    route={landingsTagsSources}
                    meta={meta}
                    {...input}
                  />
                )}
              </Field>
            </Styled.Fields>
            <Modal.Footer>
              <Button dangerTransparent onlyIcon={isMobile} type="button" onClick={showDeleteModal}>
                {isMobile ? <TrashIcon /> : t('forms:delete')}
              </Button>
              <Button type="button" transparent onClick={props.closeModal}>
                {t('landings:redirects.buttons.cancel')}
              </Button>
              <Button type="submit">{t(`landings:redirects.buttons.${isEdit ? 'save' : 'create'}`)}</Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Styled.Container>
  );
};

const mappedSubmitData = (item) => ({
  ...item,
  content_groups: item.content_groups ? item.content_groups.map((group) => group.value) : [],
});

export default withCheckIsMobile(
  withLandingData(withTranslation([LOCALES.FORMS, LOCALES.LANDINGS])(LandingsRedirectModal), {
    page: PAGES.LANDING_REDIRECTS,
    translation: 'redirects',
    sources: landingsRedirectSources,
    mappedCreateData: (submitData) => editTranslationData(submitData, (item) => mappedSubmitData(item)),
    mappedUpdateData: (submitData) => editTranslationData(submitData, (item) => mappedSubmitData(item)),
  }),
);
