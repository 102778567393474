import config from 'config/config';
import api from 'services/api';

const route = `${config.COMMON_API_URL}/admin/v2/prices`;

interface RoundingItems {
  sourceID: number; 
  event_source_id: number;
}

interface DeletePriceRuleProps {
  price_id: number; 
  rule_id: number;
}

interface UpdatePriceRule {
  price_id: number; 
  markup: {query: []
    id:number;
  };
  place_id: number;
  hall_id: number;
  hall_layout_id: number;
}

interface UpdateRoundItemProps {
  char_code: string;
  round_type:string;
  markup_round_id: number;
}

interface  CreateSourceIDRoundItem {
  char_code: string;
  round_type:string;
  source_id: number;
}
interface  CreateRoundItem {
  char_code: string;
  round_type:string;
  source_id: number;
  event_source_id: number
}

const markupSources = {
  root: `${route}`,
  detail: (id: number) => `${route}/${id}`,

  async deletePriceRule({ price_id, rule_id }: DeletePriceRuleProps) {
    const response = await api.delete(`${route}/${price_id}/rules/${rule_id}`);

    return response;
  },

  async updatePriceRule({ price_id, markup, place_id, hall_id, hall_layout_id }: UpdatePriceRule) {
    if (typeof markup.id === 'number') {
      const response = await api.patch(`${route}/${price_id}/rules/${markup.id}`, {
        ...markup.query,
        hall_id,
        place_id,
        hall_layout_id,
      });

      return response;
    }

    const response = await api.post(`${route}/${price_id}/rules`, {
      ...markup.query,
      hall_id,
      place_id,
      hall_layout_id,
    });

    return response;
  },

  async roundingsItems({ sourceID, event_source_id }: RoundingItems) {
    const response = await api.get(`${config.COMMON_API_URL}/admin/v1/markup_rounds`, {
      params: {
        source_id: sourceID,
        event_source_id,
      },
    });

    return response.data;
  },

  async createRoundItem({ source_id, char_code, round_type, event_source_id }: CreateRoundItem) {
    const queryParams = { source_id, event_source_id };

    const response = await api.post(
      `${config.COMMON_API_URL}/admin/v1/markup_rounds`,
      { char_code, round_type },
      {
        params: queryParams,
      },
    );

    return response.data;
  },

  async updateRoundItem({ char_code, round_type, markup_round_id }: UpdateRoundItemProps) {
    const response = await api.patch(`${config.COMMON_API_URL}/admin/v1/markup_rounds/${markup_round_id}`, {
      char_code,
      round_type,
    });

    return response.data;
  },

  async createSourceIDRoundItem({ source_id, char_code, round_type }: CreateSourceIDRoundItem) {
    const queryParams = { source_id };

    const response = await api.post(
      `${config.COMMON_API_URL}/admin/v1/markup_rounds`,
      {
        char_code,
        round_type,
      },
      {
        params: queryParams,
      },
    );

    return response.data;
  },
};

export default markupSources;
