import styled, { createGlobalStyle, css } from 'styled-components';
import BREAKPOINTS from '../../../constants/BREAKPOINTS';
import MEDIA_QUERIES from '../../../constants/MEDIA_QUERIES';

interface OverlayProps {
  popoverIsOpen?: boolean;
  onMouseDown?: any;
  onMouseUp?: any;
  zIndex?: number | undefined;
}

interface TableWrapperProps {
  fullSize?: boolean;
  mobileBottom?: boolean;
}

interface ModalProps {
  size?: number;
}

interface TitleProps {
  center?: boolean;
}

interface BodyProps {
  fullSize?: boolean;
  padding?: string;
  mobilePadding?: string;
  flex?: boolean;
}

interface ContainerProps {
  flex?: boolean;
}

export default {
  Overlay: styled.div<OverlayProps>`
    position: fixed;
    z-index: ${({ zIndex }) => (zIndex ? zIndex : 10000)};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.8);

    /* popoverIsOpen */
    overflow-y: ${({ popoverIsOpen }) => (popoverIsOpen ? 'hidden' : null)};
  `,

  GlobalStyles: createGlobalStyle`
    .notification-container {
      padding: 65px 30px 45px;

      @media (max-width: ${BREAKPOINTS.MD}) {
        padding: 0 16px 45px;
      }
    }
  `,

  FullSizeContainer: styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 16px);
    padding-bottom: 72px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      border-radius: 8px 8px 0 0;
      height: 100%;
      padding-bottom: 56px;
    }
  `,

  FullSizeContent: styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100%;
    overflow-y: auto;
  `,

  TableContainer: styled.div`
    display: table;
    height: 100%;
    width: 100%;
    overflow: hidden;
  `,

  TableWrapper: styled.div<TableWrapperProps>`
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    padding: ${({ fullSize }) => (fullSize ? 8 : 64)}px;
    padding-bottom: ${({ fullSize }) => (fullSize ? 8 : 80)}px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding: ${({ fullSize }) => (fullSize ? 8 : 0)}px;
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding: 0;
      vertical-align: ${({ mobileBottom }) => mobileBottom && 'bottom'};
    }
  `,

  Wrapper: styled.div<{ fullSize?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${({ fullSize }) => (fullSize ? '100%' : null)};
    height: ${({ fullSize }) => (fullSize ? '100%' : null)};
    margin: 0 auto;
  `,

  Modal: styled.div<ModalProps>`
    position: relative;
    max-width: ${({ size }) => size}px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    z-index: 100;

    @media (max-width: ${BREAKPOINTS.SM}) {
      border-radius: 0;
    }
  `,

  ButtonWrapper: styled.div`
    position: absolute;
    top: -30px;
    right: -30px;

    button {
      color: #fff;
      border: 0;
      background: none;
      cursor: pointer;
      outline: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #ffda00;
      }

      & > i {
        font-size: 20px;
      }
    }
  `,

  Header: styled.div`
    display: flex;
    padding: 24px 32px 12px;
  `,

  Title: styled.div<TitleProps>`
    font-size: 20px;
    word-break: break-all;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    margin-bottom: 32px;
  `,

  Close: styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 40px;
    height: 40px;
    background-image: url('/static/icons/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    cursor: pointer;
    z-index: 2;
    background-color: transparent;
    background-repeat: no-repeat;
    opacity: 0.24;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    @media ${MEDIA_QUERIES.MOBILE} {
      top: 0;
      right: 0;
    }
  `,

  SecondaryCloseButton: styled.button`
    position: absolute;
    top: 26px;
    right: 48px;
    display: flex;
    align-items: center;
    opacity: 0.64;
    font-weight: bold;
    font-size: 14px;
    color: #eb5757;
    background: none;
    border: 0;
    cursor: pointer;
    z-index: 1;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    & > img {
      margin-left: 8px;

      @media (max-width: ${BREAKPOINTS.SM}) {
        height: 14px;
      }
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      right: 16px;
      height: 20px;
    }
  `,

  Body: styled.div<BodyProps>`
    box-sizing: border-box;
    position: relative;
    padding: 32px 40px;
    height: ${({ fullSize }) => fullSize && '100%'};
    padding: ${({ padding }) => padding};
    background-color: #fff;
    border-radius: 8px;

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 32px;
      padding: ${({ mobilePadding }) => mobilePadding};
    }

    @media (max-width: ${BREAKPOINTS.SM}) {
      padding: 32px 16px;
      padding: ${({ mobilePadding }) => mobilePadding};
    }

    ${(props) =>
      props.flex &&
      css`
        display: flex;
        flex-direction: column;
      `}
  `,

  Popover: styled.div`
    position: fixed;
    z-index: 1001;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
  `,

  Info: styled.div`
    padding: 16px 40px;
    background-color: #f4f4f4;
    border-radius: 4px;
    background-image: url('/static/icons/question.svg');
    background-repeat: no-repeat;
    background-position: 12px 18px;

    & > p:not(:last-child) {
      margin-bottom: 4px;
    }
  `,

  InfoItem: styled.p`
    font-size: 14px;
    color: #000;
  `,

  Label: styled.span`
    @media (max-width: ${BREAKPOINTS.SM}) {
      display: none;
    }
  `,

  Container: styled.div<ContainerProps>`
    box-sizing: border-box;
    max-width: 880px;
    width: 100%;
    padding: 40px;
    margin: 0 auto;

    ${(props) =>
      props.flex &&
      css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
      `}

    @media (max-width: ${BREAKPOINTS.MD}) {
      padding: 16px;
    }
  `,

  Section: styled.div`
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #f5f5f5;

    @media (max-width: ${BREAKPOINTS.MD}) {
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  `,

  Editor: styled.div`
    box-sizing: border-box;
    display: flex;
  `,
};
