import React from 'react';
import CallContextForm from '../../../CallContextForm';
import preventClick from 'shared/helpers/preventClick';
import MODALS from 'shared/constants/MODALS';
import Styled from './styles';
import { ModalFunctions } from '../../../../../interfaces/modal';

interface ContextProps {
  contextIsVisible: boolean;
  closeSchemeContext: () => void;
  createContext: ({ name, phone }: { name: string; phone: string }) => void;
  openModal: ModalFunctions['openModal'];
  closeScheme: () => void;
  event: any;
}

const SchemeContext: React.FC<ContextProps> = ({
  contextIsVisible,
  closeSchemeContext,
  closeScheme,
  createContext,
  openModal,
  event,
}) => {
  if (!contextIsVisible) return null;

  const handleSubmitContext = (data: any) => {
    const { name, phone } = data;

    createContext({ name, phone });
    closeSchemeContext();
    closeScheme();
    openModal(MODALS.BOOK_TICKETS_MODAL, {
      event,
    });
  };

  return (
    <Styled.CallContextOverlay onClick={closeSchemeContext}>
      <Styled.CallContextFormWrapper onClick={preventClick}>
        <CallContextForm
          isOpen={contextIsVisible}
          close={closeSchemeContext}
          onSubmit={handleSubmitContext}
        />
      </Styled.CallContextFormWrapper>
    </Styled.CallContextOverlay>
  );
};

export default SchemeContext;
