import React, { Component } from 'react';
import Script from 'react-load-script';
import { formatFullDate } from 'shared/helpers/formatters/date';
import Styled from './styles';
import SchemeContextContainer from 'containers/Pages/Pool/SchemeContextContainer';
import ERRORS from 'shared/constants/ERRORS';
import { getToken } from 'shared/lib/auth';

interface ModalProps {
  event: any;
  selectTicketFromScheme: Function;
  selectedTicketsIds: any[];
}

interface ModalState {
  error: string | boolean;
}

class SchemePoolModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  componentWillUnmount() {
    if (window.pwidget) {
      window.pwidget.unmount();
      window.pwidget = null;
    }
  }

  renderWidget = () => {
    const { event, selectedTicketsIds } = this.props;

    if (!event) {
      this.setState({
        error: ERRORS.DEFAULT,
      });
      return;
    }

    window.pwidget = new window.PWidget({
      node: 'widget',
      settings: {
        withoutFullscreen: true,
        height: '100%',
        token: getToken(),
        selectedTickets: selectedTicketsIds,
      },
      poolSettings: {
        eventId: event.id,
        hallLayoutId: event.hall_layout.id,
      },
      onAddTicket: this.props.selectTicketFromScheme,
      onDeleteTicket: this.props.selectTicketFromScheme,
    });
  };

  render() {
    const { event } = this.props;
    const { error } = this.state;

    return (
      <Styled.Wrapper>
        <Styled.Widget id="widget">{error && <Styled.Error>{error}</Styled.Error>}</Styled.Widget>
        {event && (
          <Styled.Bar>
            <Styled.EventData>
              {event.title}
              {event.place && (
                <Styled.Description>
                  {`${event.place.name}, ${formatFullDate(`${event.date_start} ${event.time_start}`)}`}
                </Styled.Description>
              )}
            </Styled.EventData>
          </Styled.Bar>
        )}
        <SchemeContextContainer event={event} />
        <Script
          url={`${process.env.WIDGET_SRC}${process.env.WIDGET_VERSION}/widget.js?v=3.1.0`}
          onLoad={this.renderWidget}
        />
      </Styled.Wrapper>
    );
  }
}

export default SchemePoolModal;
