import compact from 'lodash/compact';
import find from 'lodash/find';
import includes from 'lodash/includes';
import map from 'lodash/map';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormSpy } from 'react-final-form';
import CONSTRUCTOR_HOME_SECTIONS from 'shared/constants/CONSTRUCTOR_HOME_SECTIONS';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import Form from '../../../../Form';
import ConstructorFooter from '../ConstructorFooter';
import Styled from '../styles';
import HomeSectionItem from './HomeSectionItem';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface StepProps {
  onSubmit: (data: any) => any;
  updateData: any;
  goBack: () => void;
  data: any;
  lng: string;
}

interface ISectionsState {
  id: string;
  name: string;
  value: string;
}

const HomeSectionsStep: React.FC<StepProps> = ({ onSubmit, updateData, data, goBack, lng }) => {
  const [formValues, setFormValues] = useState({});
  const [sections, setSections] = useState<ISectionsState[]>([
    { id: '0', name: CONSTRUCTOR_HOME_SECTIONS.TOP_AFISHA, value: 'Топ афиша' },
    { id: '1', name: CONSTRUCTOR_HOME_SECTIONS.AFISHA, value: 'Афиша' },
    { id: '2', name: CONSTRUCTOR_HOME_SECTIONS.NEWS, value: 'Новости' },
    { id: '3', name: CONSTRUCTOR_HOME_SECTIONS.GALLERY, value: 'Галерея' },
    { id: '4', name: CONSTRUCTOR_HOME_SECTIONS.HOW_TO, value: 'Преимущества' },
    { id: '5', name: CONSTRUCTOR_HOME_SECTIONS.LEGAL_INFO, value: 'Контакты Юр лица' },
    { id: '6', name: CONSTRUCTOR_HOME_SECTIONS.PLACE_INFO, value: 'Адрес площадки' },
    { id: '7', name: CONSTRUCTOR_HOME_SECTIONS.REQUEST_FORM, value: 'Форма для заявок' },
    { id: '9', name: CONSTRUCTOR_HOME_SECTIONS.QNA, value: 'Вопрос — Ответ' },
  ]);

  const checkFields = data.ui_config_index_ordering ? data.ui_config_index_ordering : [];

  const checkFieldsBlockNames = reduce(
    data,
    (acc, curr, key) => {
      if (key.indexOf('ui_config_qna_name') !== -1 || key.indexOf('ui_config_block_name_') !== -1) {
        acc[key] = curr;
      }

      return acc;
    },
    {},
  );

  const initialData = checkFields.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  useEffect(() => {
    const topSections = compact(
      checkFields.map((item: any) => find(sections, { name: item }) && find(sections, { name: item })),
    );

    const newSections: any = [...topSections, ...sections.filter((item) => !includes(topSections, item))];
    setSections(newSections);
  }, []);

  const currentValues = compact(map(formValues, (item, index) => item && index));
  const sectionsArray = sections.map((item) => item.name);
  const ordering = compact(sectionsArray.map((item) => includes(currentValues, item) && item));
  const requestData = {
    ...data,
    ui_config_index_ordering: ordering,
  };
  const blockNames = pick(formValues, Object.keys(checkFieldsBlockNames));

  const handleRequestData = (values) => {
    if (values.submit_action === SUBMIT_ACTIONS.CREATE) {
      return onSubmit({ ...requestData, ...blockNames, submit_action: SUBMIT_ACTIONS.CREATE });
    }

    return onSubmit({ ...requestData, ...blockNames, submit_action: SUBMIT_ACTIONS.CONTINUE });
  };

  const handleUpdateData = () => updateData({ ...requestData, ...blockNames });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items: any = reorder(sections, result.source.index, result.destination.index);

    setSections(items);
  };

  return (
    <Styled.Container withTitle>
      <Styled.Header>
        <Styled.Title>Настройка блоков</Styled.Title>
      </Styled.Header>

      <Styled.Content>
        <Form
          onSubmit={handleRequestData}
          initialValues={{
            ...initialData,
            ...checkFieldsBlockNames,
            ui_config_block_name_faq: 'Наши преимущества',
          }}
          render={({ handleSubmit, form, values: formValuesAll }) => (
            <>
              <FormSpy subscription={{ values: true }} onChange={({ values }) => setFormValues(values)} />
              <form onSubmit={handleSubmit}>
                <Styled.FormRow>
                  <Styled.FormDescription>
                    Блоки на главной странице сайта
                    <Styled.FormSmallDescription>Показ и порядок блоков</Styled.FormSmallDescription>
                  </Styled.FormDescription>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <Styled.Sections ref={provided.innerRef} {...provided.droppableProps}>
                          {sections.map((section, index) => (
                            <HomeSectionItem
                              lng={lng}
                              section={section}
                              key={section.id}
                              index={index}
                              values={formValuesAll}
                            />
                          ))}
                          {provided.placeholder}
                        </Styled.Sections>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Styled.FormRow>

                <ConstructorFooter goBack={goBack} form={form} updateData={handleUpdateData} />
              </form>
            </>
          )}
        />
      </Styled.Content>
    </Styled.Container>
  );
};

export default HomeSectionsStep;
