import { TranslatedInfo } from '../api/types';

export interface TranslationInfoParams extends Omit<TranslatedInfo, 'address'> {
  address?: string;
  name?: string;
}

export const findTranslation = <T extends { language_code: string } = TranslationInfoParams>(
  info: T[],
  lang: string,
): T | undefined => {
  return info?.find((item) => item.language_code === lang);
};
