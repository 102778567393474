import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import isArray from 'lodash/isArray';
import { NotificationManager } from 'react-notifications';
import { changeModalState, openModal } from 'entities/modal';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import ConfirmModal from 'components/Modals/ConfirmModal';
import Spinner from 'components/Spinner';
import MODALS from 'shared/constants/MODALS';
import PAGES from 'shared/constants/PAGES';
import { i18n, useTranslation } from '../../../i18n';
import LOCALES from 'shared/constants/LOCALES';
import useListItem from 'shared/lib/useListItem';
import useLandings from './useLandings';
import { ModalFunctions } from '../../../../interfaces/modal';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { USE_LIST_RELOAD } from 'shared/lib/useList';
import Emmiter from 'shared/services/Emmiter';
import { EVENTS } from 'shared/constants/EVENTS';

interface Props {
  data: any;
  modalState?: any;
  closeModal: ModalFunctions['closeModal'];
  forceCloseModal: ModalFunctions['forceCloseModal'];
  page?: string;
  translation?: string;
}

function withLandingData(
  WrappedComponent: any,
  {
    sources,
    translation,
    params = {},
    page,
    mappedCreateData,
    mappedUpdateData,
    emitUpdateTableAfterSave,
  }: any,
) {
  const LandingData = (props: Props) => {
    const { modalState, forceCloseModal } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation([LOCALES.LANDINGS, LOCALES.FORMS]);
    const { get, remove, emitListReload, onListItemUpdate } = useListItem({
      source: sources,
    });
    const { create, update } = useLandings({
      source: sources,
      mappedCreateData,
      mappedUpdateData,
      query: params,
      page,
    });
    const [langLoading, setLangLoading] = useState<boolean>(false);
    const [state, setState] = useState<any>({
      data: props.data,
      showDeletePopover: false,
      isEmpty: true,
      lng: i18n.language || 'ru',
    });
    const mounted = useRef(true);

    const loadData = (lng = 'ru') => {
      get(props.data.id, lng, params)
        .then((data) => {
          const is404 = data.status === 404;

          if (is404 && data.lng === LANGUAGES.RU) {
            NotificationManager.error('Ошибка');
            forceCloseModal();
            return;
          }

          setState((prevState) => ({
            ...prevState,
            data: is404 ? { create: true, id: props.data.id } : { ...data, id: props.data.id },
            isLoading: false,
            isEmpty: false,
            lng,
          }));
          setLangLoading(false);
        })
        .catch((err) => new Error(err));
    };

    useEffect(() => {
      if (modalState !== MODAL_STATES.CREATE) {
        loadData();
      } else {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }

      return () => {
        mounted.current = false;
      };
    }, []);

    const onChangeLng = (lng) => {
      if (modalState !== MODAL_STATES.CREATE) {
        setState((prevState) => ({
          ...prevState,
          lng,
        }));
      }
    };
    const [modalTab, setModalTab] = useState(null);

    const setFormData = (data, tabPoint?) => {
      const modalOverlay = document.getElementById('modal_container');

      setState((prevState) => ({
        ...prevState,
        data,
        isLoading: false,
        isEmpty: false,
      }));
      dispatch(changeModalState(MODAL_STATES.EDIT));

      Emmiter.emit(USE_LIST_RELOAD);
      Emmiter.emit(EVENTS.GO_NEXT_STEP);

      if (tabPoint) {
        setModalTab(tabPoint);
      }

      modalOverlay.scrollTop = 0;
    };

    const updateTranslations = (data) => {
      setState((prevState) => {
        const index = prevState.data.info.findIndex((item) => item.language_code === data.language_code);
        const updatedInfo = prevState.data.info;

        if (index !== -1) {
          updatedInfo[index] = data;
        } else {
          updatedInfo.push(data);
        }

        return {
          ...prevState,
          data: {
            ...prevState.data,
            info: updatedInfo,
          },
        };
      });
    };

    const updateData = (data) => {
      setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          ...data,
        },
      }));
    };

    const hideDeleteModal = () => {
      if (mounted.current) {
        setState((prevState) => ({
          ...prevState,
          showDeletePopover: false,
        }));
      }
    };

    const showDeleteModal = () => {
      if (mounted.current) {
        setState((prevState) => ({
          ...prevState,
          showDeletePopover: true,
        }));
      }
    };

    const deleteItem = () =>
      remove({ id: state.data.id, params }).then((response) => {
        forceCloseModal();
        if (
          response &&
          isArray(response.error) &&
          response.error[0] === 'You can not delete the object.' &&
          page === PAGES.LANDING_TEMPLATE
        ) {
          return NotificationManager.error('Удаление невозможно. Данный шаблон используется.', 'Ошибка');
        }

        if (response.blocked_by_elements && page === PAGES.LANDING_TAGS) {
          return dispatch(
            openModal(MODALS.LANDINGS_ERRORS_MODAL, {
              errors: response.blocked_by_elements,
              name: state.data.name,
              page: PAGES.LANDING_TAGS,
            }),
          );
        }

        if (response.blocked_by_elements && page === PAGES.LANDING_PERSONALITIES) {
          return dispatch(
            openModal(MODALS.LANDINGS_ERRORS_MODAL, {
              errors: response.blocked_by_elements,
              name: state.data.title,
              page: PAGES.LANDING_PERSONALITIES,
            }),
          );
        }

        emitListReload();
        return true;
      });

    const updateList = () => {
      emitListReload();
      props.forceCloseModal();
    };

    const onUpdate = (updatedData) => {
      if (updatedData.ru) {
        onListItemUpdate(updatedData.ru);
      }

      if (emitUpdateTableAfterSave) {
        emitListReload();
      }

      props.forceCloseModal();
    };
    const onUpdateLandingEvents = (updatedData) => {
      if (updatedData) {
        onListItemUpdate(updatedData);
      }

      if (emitUpdateTableAfterSave) {
        emitListReload();
      }
    };

    return (
      <>
        {modalState !== MODAL_STATES.CREATE && state.isEmpty ? (
          <Spinner size={50} center />
        ) : (
          <WrappedComponent
            {...props}
            langLoading={langLoading}
            onChangeLng={onChangeLng}
            onListItemUpdate={onListItemUpdate}
            updateList={updateList}
            updateTranslations={updateTranslations}
            updateData={updateData}
            data={state.data}
            lng={state.lng}
            showDeleteModal={showDeleteModal}
            setFormData={setFormData}
            onUpdate={onUpdate}
            onUpdateLandingEvents={onUpdateLandingEvents}
            create={create}
            update={update}
            modalTab={modalTab}
            t={t}
          />
        )}

        {state.showDeletePopover && (
          <ConfirmModal
            withPopover
            data={{
              title: t(`landings:${translation}.delete_title`),
              text: t(`landings:${translation}.delete_text`),
              onSubmit: deleteItem,
            }}
            closeModal={hideDeleteModal}
            padding="24px"
          />
        )}
      </>
    );
  };

  LandingData.defaultProps = {
    modalState: null,
    page: null,
    translation: 'common',
  };

  return LandingData;
}

export default withLandingData;
