export enum AttachmentType {
  Cover = 'cover',
  Preview = 'preview',
  Gallery = 'gallery',
  Gallery_Web = 'gallery_webp',
  Main_Image = 'main_image',
  Schemas = 'schemas',
}

export const AttachmentItem = {
  main_image: { id: 4 },
  schemas_webp: { id: 6 },
};
