import AfishaStep from 'components/Pages/Landings/ConstructorModal/AfishaStep';
import LogoStep from 'components/Pages/Landings/ConstructorModal/LogoStep';
import MenuStep from 'components/Pages/Landings/ConstructorModal/MenuStep';
import HeroStep from 'components/Pages/Landings/ConstructorModal/HeroStep';
import HeroTypesStep from 'components/Pages/Landings/ConstructorModal/HeroTypesStep';
import AfishaTopStep from 'components/Pages/Landings/ConstructorModal/AfishaTopStep';
import NewsStep from 'components/Pages/Landings/ConstructorModal/NewsStep';
import FinishStep from 'components/Pages/Landings/ConstructorModal/FinishStep';
import GalleryStep from 'components/Pages/Landings/ConstructorModal/GalleryStep';
import ContactsStep from 'components/Pages/Landings/ConstructorModal/ContactsStep';
import FooterStep from 'components/Pages/Landings/ConstructorModal/FooterStep';
import SectionsStep from 'components/Pages/Landings/ConstructorModal/SectionsStep';
import HomeSectionsStep from 'components/Pages/Landings/ConstructorModal/HomeSectionsStep';
import MediaStep from 'components/Pages/Landings/ConstructorModal/MediaStep';
import FaviconStep from 'components/Pages/Landings/ConstructorModal/FaviconStep';
import RequestFormStep from 'components/Pages/Landings/ConstructorModal/RequestFormStep';
import PersonalityStep from 'components/Pages/Landings/ConstructorModal/PersonalityStep';
import FiltersStep from 'components/Pages/Landings/ConstructorModal/FiltersStep';
import TabsStep from 'components/Pages/Landings/ConstructorModal/TabsStep';
import QNAStep from 'components/Pages/Landings/ConstructorModal/QNAStep';
import PlacesStep from 'components/Pages/Landings/ConstructorModal/PlacesStep';
import { LANGUAGES } from './LANGUAGES';
import NotificationsStep from 'components/Pages/Landings/ConstructorModal/NotificationsStep';

const STEPS = {
  [LANGUAGES.RU]: {
    MENU: 0,
    SECTIONS: 1,
    LOGO: 2,
    AFISHA: 3,
    AFISHA_TOP: 4,
    FILTERS: 5,
    NEWS: 6,
    GALLERY: 7,
    FORM: 8,
    PERSONALITY: 9,
    PLACES: 10,
    MEDIA: 11,
    HERO: 12,
    HERO_TYPES: 13,
    TABS: 14,
    CONTACTS: 15,
    FOOTER: 16,
    HOME_SECTIONS: 17,
    QNA: 18,
    FAVICON: 19,
    NOTIFICATIONS: 20,
    FINISH: 21,
  },
  translations: {
    SECTIONS: 0,
    HERO: 1,
    HOME_SECTIONS: 2,
    CONTACTS: 3,
    FINISH: 4,
  },
};

export default {
  [LANGUAGES.RU]: {
    STEPS_WORDS: [
      'Меню сайта',
      'Разделы',
      'Логотип',
      'Афиша',
      'Топ Афиши',
      'Фильтры',
      'Новости',
      'Галерея',
      'Форма',
      'Персоналии',
      'Площадки',
      'Настройки медиа',
      'Обложка',
      'Варианты обложки',
      'Табы',
      'Контакты',
      'Подвал',
      'Настройка блоков',
      'Вопрос — Ответ',
      'Фавиконка',
      'Оповещения',
      'Завершение',
    ],
    STEPS: STEPS[LANGUAGES.RU],
    STEP_COMPONENT: {
      [STEPS[LANGUAGES.RU].MENU]: MenuStep,
      [STEPS[LANGUAGES.RU].LOGO]: LogoStep,
      [STEPS[LANGUAGES.RU].AFISHA]: AfishaStep,
      [STEPS[LANGUAGES.RU].AFISHA_TOP]: AfishaTopStep,
      [STEPS[LANGUAGES.RU].FILTERS]: FiltersStep,
      [STEPS[LANGUAGES.RU].NEWS]: NewsStep,
      [STEPS[LANGUAGES.RU].GALLERY]: GalleryStep,
      [STEPS[LANGUAGES.RU].MEDIA]: MediaStep,
      [STEPS[LANGUAGES.RU].FORM]: RequestFormStep,
      [STEPS[LANGUAGES.RU].PERSONALITY]: PersonalityStep,
      [STEPS[LANGUAGES.RU].PLACES]: PlacesStep,
      [STEPS[LANGUAGES.RU].HERO]: HeroStep,
      [STEPS[LANGUAGES.RU].HERO_TYPES]: HeroTypesStep,
      [STEPS[LANGUAGES.RU].TABS]: TabsStep,
      [STEPS[LANGUAGES.RU].CONTACTS]: ContactsStep,
      [STEPS[LANGUAGES.RU].FOOTER]: FooterStep,
      [STEPS[LANGUAGES.RU].SECTIONS]: SectionsStep,
      [STEPS[LANGUAGES.RU].HOME_SECTIONS]: HomeSectionsStep,
      [STEPS[LANGUAGES.RU].QNA]: QNAStep,
      [STEPS[LANGUAGES.RU].FAVICON]: FaviconStep,
      [STEPS[LANGUAGES.RU].NOTIFICATIONS]: NotificationsStep,
      [STEPS[LANGUAGES.RU].FINISH]: FinishStep,
    },
  },
  translations: {
    STEPS_WORDS: ['Разделы', 'Обложка', 'Настройка блоков', 'Контакты', 'Завершение'],
    STEPS: STEPS.translations,
    STEP_COMPONENT: {
      [STEPS.translations.HERO]: HeroStep,
      [STEPS.translations.SECTIONS]: SectionsStep,
      [STEPS.translations.HOME_SECTIONS]: HomeSectionsStep,
      [STEPS.translations.CONTACTS]: ContactsStep,
      [STEPS.translations.FINISH]: FinishStep,
    },
  },
};
