import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const companyId = getCookie(COOKIES.COMPANY);

export default {
  root: `${config.LANDING_API_URL}/api/v1/landing`,
  phones: 'landing/site/phones',
  newDetail: (techName: string) => `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${techName}`,
  detail: (techName: string) => `${config.LANDING_API_URL}/api/v1/landing/${techName}`,

  bulkUpdate: `${config.LANDING_API_URL}/api/v1/landing/bulk_update`,
  bulkUpdateQna: 'landing/qna/bulk',
  languages: `${config.LANDING_API_URL}/api/v1/supported_languages`,

  async createQnA(id: number, qnaforUpdate: any) {
    const promises = qnaforUpdate.map((qnaforUpdateId) => {
      return api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${id}/qna`, {
        qna_id: qnaforUpdateId,
      });
    });

    return Promise.all(promises);
  },

  async deleteQnA(id: number[], qnaforDelete: number[]) {
    const promises = qnaforDelete.map((qnaforDeleteId) => {
      return api.delete(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/landings/${id}/qna/${qnaforDeleteId}`,
      );
    });

    return Promise.all(promises);
  },
};
