import config from 'config/config';
import { createEffect } from 'effector';
import { Router } from 'next/router';
import api from 'services/api';

interface CreteMarkupProps {
  title: string | undefined;
  data?: { hall_layout: { value: number }; place: { value: number } };
}

interface CreatePriceRuleProps {
  price_id: number | undefined;
  markup?: { query: [] } | undefined;
}

interface MarkupItemUpdateProps {
  priceId: number | undefined;
  data: {
    company_id: number;
    created_at: string;
    currency_code: string;
    markup_name: string;
    filters: [];
    submit_action: string;
    has_events: boolean;
    id: number;
    title: string;
    updated_at: string;
    display_type: string;
    leftover_threshold: string;
    is_table: boolean;
    use_announcements: boolean;
    round_enum: string;
    hall_layout: { value: string };
    place: { value: string };
  };
}

const route = `${config.COMMON_API_URL}/admin/v2/prices`;

export const markupItemsFx = createEffect(async (query: Router['query'] = {}) => {
  const response = await api.get(route, {
    params: {
      ...query,
      search_string: query.search_string,
      limit: query.limit ? query.limit : 25,
    },
  });

  return response.data;
});

export const createMarkupFx = createEffect(async ({ title, data }: CreteMarkupProps) => {
  const response = await api.post(route, {
    title,
    use_currency: true,
    round_enum: 'unit',
    currency_code: 'RUB',
    hall_layout_id: data?.hall_layout?.value,
    place_id: data?.place?.value,
  });

  return response.data;
});
export const createCopyMarkupFx = createEffect(async ({ title, data }: CreteMarkupProps) => {
  const response = await api.post(route, {
    title,
    use_currency: true,
    round_enum: 'unit',
    currency_code: 'RUB',
    hall_layout_id: data?.hall_layout?.value,
    place_id: data?.place?.value,
  });

  return response.data;
});

export const markupItemFx = createEffect(async (priceId: number) => {
  const response = await api.get(`${route}/${priceId}`);

  return response.data;
});

export const markupItemUpdateFx = createEffect(async ({ priceId, data }: MarkupItemUpdateProps) => {
  const response = await api.patch(`${route}/${priceId}`, {
    title: data.markup_name,
    filters: data.filters,
    submit_action: data.submit_action,
    display_type: data.display_type,
    leftover_threshold: data.leftover_threshold,
    is_table: data.is_table,
    use_announcements: data.use_announcements,
    round_enum: data.round_enum,
    hall_layout_id: data?.hall_layout?.value,
    place_id: data?.place?.value,
  });

  return response.data;
});

export const copyMarkupItemUpdateFx = createEffect(async ({ priceId, data }: MarkupItemUpdateProps) => {
  const response = await api.patch(`${route}/${priceId}`, {
    title: data.markup_name,
    filters: data.filters,
    submit_action: data.submit_action,
    display_type: data.display_type,
    leftover_threshold: data.leftover_threshold,
    is_table: data.is_table,
    use_announcements: data.use_announcements,
    round_enum: data.round_enum,
    hall_layout_id: data?.hall_layout?.value,
    place_id: data?.place?.value,
  });

  return response.data;
});

export const rulesFx = createEffect(async (priceId: number | undefined) => {
  const response = await api.get(`${route}/${priceId}/rules`);

  return {
    markup: response.data.map((markup: { id: number }) => ({ id: markup.id, query: markup })),
  };
});

export const markupItemDeleteFx = createEffect(async (priceId: number, replacingPriceId: number | null) => {
  const response = await api.delete(`${route}/${priceId}`, { params: { new_price_id: replacingPriceId } });

  return response.data;
});

export const markupSources = {
  root: `${route}`,
  detail: (id: number) => `${route}/${id}`,

  async rules(priceId: number | undefined) {
    const response = await api.get(`${route}/${priceId}/rules`);

    return {
      markup: response.data.map((markup: { id: number }) => ({ id: markup.id, query: markup })),
    };
  },

  async createPriceRule({ price_id, markup }: CreatePriceRuleProps) {
    const response = await api.post(`${route}/${price_id}/rules`, markup?.query);

    return response;
  },

  async markupItemDelete(priceId: number, replacingPriceId: number | null) {
    const response = await api.delete(`${route}/${priceId}`, { params: { new_price_id: replacingPriceId } });

    return response.data;
  },
};
