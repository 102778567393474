export const TRIGGERS = {
  TIMER: 'TIMER',
};

export const TIMER_VALUES = [
  { value: 0, label: 'Мгновенно' },
  { value: 10, label: '10 сек' },
  { value: 30, label: '30 сек' },
  { value: 60, label: '1 мин' },
  { value: 120, label: '2 мин' },
  { value: 180, label: '3 мин' },
  { value: 240, label: '4 мин' },
  { value: 300, label: '5 мин' },
  { value: 600, label: '10 мин' },
  { value: 'all', label: 'Всегда' },
];

export const REPEAT_VALUES = [
  { value: 0, label: 'Не повторять' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 9999, label: 'Всегда' },
];

export const TRIGGER_TYPES = [{ id: 1, value: TRIGGERS.TIMER, label: 'Таймер' }];
