import { invoke } from '@withease/factories';
import { createStore, sample } from 'effector';
import { createPagesMountStates } from 'shared/factory';
import { fetchPlacesFx, Places } from 'shared/api/reference/places';

export const { pageMounted, $query, $loading } = invoke(createPagesMountStates);

export const $places = createStore<Places>({
  results: [],
  count: 0,
  last_page: 0,
  limit: 0,
  page: 0,
});

sample({
  source: $query,
  fn: () => true,
  target: $loading,
});

sample({
  source: {
    query: $query,
  },
  fn: ({ query }) => query,
  target: fetchPlacesFx,
});

sample({
  clock: fetchPlacesFx.done,
  fn: ({ result }) => {
    return result;
  },
  target: $places,
});

sample({
  clock: fetchPlacesFx.done,
  fn: () => false,
  target: $loading,
});
