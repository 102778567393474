import Button from 'components/Button';
import ConfirmModal from 'components/Modals/ConfirmModal';
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { TABLE_EVENTS } from 'shared/constants/EVENTS';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import LOCALES from 'shared/constants/LOCALES';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import Emmiter from 'shared/services/Emmiter';
import qnaSources from 'shared/sources/reference/qna';
import TrashIcon from '../../../../../static/icons/trash-alt.svg';
import { useTranslation } from '../../../../i18n';
import { getTranslations } from '../../../landings/helpers/landingHelpers';
import QnAListForm from './form';

interface QnAUpdateProps {
  onSuccess: (data: any) => any;
  onChangeLng: () => any;
  data: any;
  isMobile: boolean;
  lng: string;
  closeModal: () => any;
}

const QnAListUpdate: React.FC<QnAUpdateProps> = (props) => {
  const { data, onSuccess, onChangeLng, isMobile, lng, closeModal, forceCloseModal } = props;
  const { t } = useTranslation([LOCALES.REFERENCES]);
  const [deleteModalShow, setShowDeleteModal] = useState(false);

  const showDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteItem = async () => {
    await qnaSources.qnaItemDelete(data.data.id).then((action: any) => {
      if (action.error) {
        NotificationManager.error('Ошибка');
      }
      forceCloseModal();
      NotificationManager.success('Успешно');
    });
    setShowDeleteModal(false);

    Emmiter.emit(TABLE_EVENTS.UPDATE_TABLE);
  };

  let currentInfo = null;
  if (data && data.data.info) {
    currentInfo = data.data.info.find((item) => item.language_code === lng);
  }

  const initialValues = {
    question: currentInfo ? currentInfo.question : '',
    answer: currentInfo ? currentInfo.answer : '',
    title: data.data.title,
    weight: data.data.weight,
  };

  const handleUpdate = async (submitData) => {
    const formLang = submitData.lng;
    const formData = submitData[formLang];

    if (formLang === LANGUAGES.RU) {
      await qnaSources.qnaItemUpdate(data.data.id, submitData);
    }

    const translations = getTranslations(data.data.info, formLang);
    if (translations) {
      await qnaSources.qnaItemUpdateTranslation(data.data.id, formLang, formData);
    } else {
      await qnaSources.qnaItemCreateTranslation(data.data.id, formData, formLang);
    }
    Emmiter.emit(TABLE_EVENTS.UPDATE_TABLE);
    return true;
  };

  return (
    <>
      {deleteModalShow && (
        <ConfirmModal
          withPopover
          data={{
            title: t(`qna.delete_title`),
            text: t(`qna.delete_text`),
            onSubmit: deleteItem,
          }}
          closeModal={hideDeleteModal}
          padding="24px"
        />
      )}

      <QnAListForm
        onSubmit={handleUpdate}
        isEdit
        onSuccess={onSuccess}
        title="Редактировать QnA"
        initialValues={initialValues}
        onChangeLng={onChangeLng}
      >
        <Button dangerTransparent onlyIcon={isMobile} type="button" onClick={showDeleteModal}>
          {isMobile ? <TrashIcon /> : t('forms:delete')}
        </Button>
        <Button type="button" transparent onClick={closeModal}>
          {t('forms:cancel')}
        </Button>
        <Button type="submit">{t('forms:save')}</Button>
      </QnAListForm>
    </>
  );
};

export default withCheckIsMobile(QnAListUpdate);
