import React, { Component } from 'react';
import head from 'lodash/head';
import last from 'lodash/last';
import classnames from 'classnames';
import AntdDatePicker from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/time-picker/style/index.css';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/input/style/index.css';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import moment from 'moment';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';
import IDS from 'shared/constants/IDS';
import CalendarIcon from '../../../../static/icons/calendar.svg';
import CloseIcon from '../../../../static/icons/cancel-alt.svg';
import Button from '../../Button';
import Styled from './styles';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';

const { RangePicker } = AntdDatePicker;

const disabledDate = (current) => {
  if (current < moment().startOf('day')) {
    return true;
  }

  return false;
};

export interface PickerProps {
  handleForm?: any;
  onChange: Function;
  saveDate?: ({ gte, lte }: { gte: string; lte: string }) => void;
  withoutOutdated?: boolean;
  withMobile?: boolean;
  isMobile?: boolean;
  size?: 'large' | 'small';
  placeholder?: [string, string];
  value: string | string[] | any[];
  queryName?: {
    gte: string;
    lte: string;
  };
  useStubTime?: boolean;
}

interface PickerState {
  isOpen: boolean;
}

class RangeDateTimePicker extends Component<PickerProps, PickerState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    size: 'large',
    placeholder: '',
    withoutOutdated: false,
    withMobile: false,
    isMobile: false,
    saveDate: null,
  };

  pickerContainer: any;

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.pickerContainer = null;
  }

  componentDidMount() {
    this.pickerContainer = document.getElementById(IDS.POPUP_CONTAINER);
    document.getElementById(IDS.APP_LAYOUT).addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.getElementById(IDS.APP_LAYOUT).removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.hideDatePicker();
  };

  handleChange = (event, dateString) => {
    const { handleForm, onChange, saveDate, queryName, useStubTime } = this.props;
    const timeGTEStub = 'T00:00:00';
    const timeLTEStub = 'T23:59:59';

    const from = head(dateString);
    const to = last(dateString);

    let fromValue = from ? moment(from, DATE_FORMATS.DATE_TIME_PREVIEW).format(DATE_FORMATS.DATE) : '';
    let toValue = to ? moment(to, DATE_FORMATS.DATE_TIME_PREVIEW).format(DATE_FORMATS.DATE) : '';

    if (useStubTime && fromValue && toValue) {
      fromValue = `${fromValue}${timeGTEStub}`;
      toValue = `${toValue}${timeLTEStub}`;
    }

    if (typeof saveDate === 'function') {
      saveDate({ gte: fromValue, lte: toValue });
    }
    if (handleForm) {
      handleForm.change(queryName.gte, fromValue);
      handleForm.change(queryName.lte, toValue);
    }

    onChange(event, fromValue, toValue);
  };

  handleOnOpenChange = () => {
    this.toggleDatePicker();
  };

  hideDatePicker = () => {
    this.setState({
      isOpen: false,
    });
  };

  toggleDatePicker = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  mobileFooter = () => {
    const { isMobile } = this.props;

    if (isMobile) {
      return (
        <Styled.Close>
          <Button type="button" onlySmallIcon transparent onClick={this.hideDatePicker}>
            <CloseIcon />
          </Button>
        </Styled.Close>
      );
    }

    return null;
  };

  render() {
    const { value, size, placeholder, withoutOutdated, withMobile, isMobile } = this.props;
    const from = head(value);
    const to = last(value);
    const initialFrom = from ? moment(from, DATE_FORMATS.DATE_TIME) : undefined;
    const initialTo = to ? moment(to, DATE_FORMATS.DATE_TIME) : undefined;

    return (
      <>
        <RangePicker
          className={classnames({
            'range-date-picker': true,
            'range-date-picker--with-mobile': withMobile,
            'range-date-picker__without-value': !(initialFrom && initialTo),
          })}
          open={this.state.isOpen}
          onChange={this.handleChange}
          placeholder={placeholder}
          showTime={false}
          locale={locale}
          format={DATE_FORMATS.DATE_DOTS}
          disabledDate={withoutOutdated ? disabledDate : undefined}
          getCalendarContainer={isMobile ? () => this.pickerContainer : null}
          ranges={{
            Сегодня: [moment(), moment()],
            Вчера: [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
            'Текущая неделя': [moment().startOf('week'), moment().endOf('week')],
            'Прошлая неделя': [
              moment().subtract(1, 'week').startOf('week'),
              moment().subtract(1, 'week').endOf('week'),
            ],
            'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
            'Прошлый месяц': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            'Текущий год': [moment().startOf('year'), moment().endOf('year')],
          }}
          value={[initialFrom, initialTo]}
          onOpenChange={this.handleOnOpenChange}
          size={size}
          separator="-"
          renderExtraFooter={this.mobileFooter}
        />
        {withMobile && (
          <Styled.Calendar>
            <Button onlyIcon transparent type="button" onClick={this.toggleDatePicker}>
              <CalendarIcon />
            </Button>
          </Styled.Calendar>
        )}
      </>
    );
  }
}

export default withCheckIsMobile(RangeDateTimePicker);
