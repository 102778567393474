import { createEvent, sample } from 'effector';
import { $query } from 'pages/markups/model';
import * as markupsApi from 'shared/api/markups';

export const deletedMarkup = createEvent();

sample({
  source: { query: $query },
  clock: deletedMarkup,
  fn: ({ query }) => query,
  target: markupsApi.markupItemsFx,
});
