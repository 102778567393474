import cn from 'classnames';
import React from 'react';
import Styled from './styles';

interface ModalFooterProps {
  contentWidth?: string;
  fullSize?: boolean;
  justify?: boolean;
  children: any;
  marginTop?: string;
  spaceBetween?: boolean;
}

const ModalFooter: React.FC<ModalFooterProps> = ({
  children,
  contentWidth,
  fullSize,
  justify,
  marginTop,
}) => {
  const buttonsCount = children.length;
  const footerClassNames = cn('modal__footer', {
    modal__footer_inline: !fullSize,
  });

  if (buttonsCount === 3 || justify) {
    const [first, ...rest] = children;

    return (
      <Styled.Container className={footerClassNames} marginTop={marginTop}>
        <Styled.Content contentWidth={contentWidth}>
          <Styled.LeftAlignedButton>{first}</Styled.LeftAlignedButton>
          {rest}
        </Styled.Content>
      </Styled.Container>
    );
  }

  return (
    <Styled.Container className={footerClassNames} marginTop={marginTop}>
      <Styled.Content contentWidth={contentWidth}>{children}</Styled.Content>
    </Styled.Container>
  );
};

export default ModalFooter;
