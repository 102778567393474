import Button from 'components/Button';
import Textarea from 'components/FormControls/Textarea';
import { useUnit } from 'effector-react';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { required } from 'shared/helpers/validations';
import { Modal, ModalClose } from 'shared/ui/modal';
import { sourceCodeModal, sourceCodeToggler } from '../model';
import Styled from './styles';

export const SourceCodeModal = () => {
  const { data, handleSourceCode } = useUnit({
    data: sourceCodeModal.$data,
    handleSourceCode: sourceCodeModal.instertedText,
  });

  return (
    <Modal toggler={sourceCodeToggler}>
      {({ closeModal }) => {
        return (
          <Styled.Body className="modal__body">
            <ModalClose onClick={closeModal} />
            <Styled.Title>Исходный код</Styled.Title>
            <Form
              onSubmit={() => {}}
              initialValues={{ sourceCodeText: data?.value }}
              render={({ values }) => {
                return (
                  <form>
                    <Field name="sourceCodeText" validate={required}>
                      {({ input, meta }) => (
                        <Textarea value={input.value} meta={meta} height="78vh" onChange={input.onChange} />
                      )}
                    </Field>

                    <Styled.SourceCodeButton>
                      <Button type="button" onClick={closeModal} transparent>
                        Отмена
                      </Button>
                      <Styled.SaveButton>
                        <Button type="button" onClick={() => handleSourceCode(values)}>
                          Сохранить
                        </Button>
                      </Styled.SaveButton>
                    </Styled.SourceCodeButton>
                  </form>
                );
              }}
            />
          </Styled.Body>
        );
      }}
    </Modal>
  );
};
