import Button from 'components/Button';
import { LangForm } from 'components/Form';
import {
  Textarea,
  FormDescription,
  SmallFormDescription,
  FormControl,
  LangSwitcher,
  FormRow,
  NumberInput,
} from 'components/FormControls';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import LandingsModalLayout from 'containers/Pages/Landings/landingsModalLayout';
import React from 'react';
import { Field } from 'react-final-form';
import { Editor } from 'entities/editor';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { required, landingDescriptionValidation } from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import { ModalFunctions } from '../../../../../interfaces/modal';

interface FormProps {
  title: string;
  closeModal: ModalFunctions['closeModal'];
  onChangeLng?: (lng: string) => any;
  isDesktop: boolean;
  isMobile: boolean;
  isEdit?: boolean;
  initialValues?: any;
  children?: any;
}

const QnAListForm: React.FC<FormProps> = ({
  children,
  title,
  closeModal,
  isMobile,
  isDesktop,
  isEdit,
  onChangeLng,
  ...rest
}) => {
  return (
    <LangForm
      {...rest}
      initialValues={{
        ...rest.initialValues,
      }}
      onChangeLng={onChangeLng}
      stashLanguages={!isEdit}
      render={({ handleSubmit, form, i18n, switcher }) => {
        const fieldAvailable = i18n.language === LANGUAGES.RU;

        return (
          <form onSubmit={handleSubmit}>
            <LandingsModalLayout.GlobalStyles />
            <Modal.FullSizeContainer>
              <StepModal.Header>
                <StepModal.WithLanguage>
                  <StepModal.Title>{title}</StepModal.Title>
                  {isDesktop && <LangSwitcher />}
                </StepModal.WithLanguage>
              </StepModal.Header>
              <Modal.FullSizeContent>
                <Modal.Container>
                  {!isDesktop && <Modal.Section>{switcher}</Modal.Section>}

                  <Modal.Section>
                    <LandingsModalLayout.Row grid>
                      <FormDescription width={208}>
                        Вопросы и ответы
                        <SmallFormDescription>Заполнение блока</SmallFormDescription>
                      </FormDescription>
                      <FormControl column>
                        <FormRow>
                          <Field name={`${i18n.language}.question`} validate={required}>
                            {({ input, meta }) => <Textarea label="Вопрос" {...input} meta={meta} />}
                          </Field>
                        </FormRow>
                      </FormControl>
                    </LandingsModalLayout.Row>
                    <FormRow>
                      <Field name={`${i18n.language}.answer`} validate={landingDescriptionValidation}>
                        {({ input, meta }) => (
                          <Editor id="qna_1" key={i18n.language} label="Ответ" input={input} meta={meta} />
                        )}
                      </Field>
                    </FormRow>
                  </Modal.Section>
                  {fieldAvailable && (
                    <>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>Название</FormDescription>
                          <FormControl column>
                            <FormRow>
                              <Field name={`${i18n.language}.title`} validate={required}>
                                {({ input, meta }) => <Textarea label="Название" {...input} meta={meta} />}
                              </Field>
                            </FormRow>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>Позиция в списке</FormDescription>
                          <FormControl column>
                            <FormRow>
                              <Field name={`${i18n.language}.weight`} validate={required}>
                                {({ input, meta }) => (
                                  <NumberInput label="Позиция в списке" meta={meta} {...input} min={0} />
                                )}
                              </Field>
                            </FormRow>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                    </>
                  )}
                </Modal.Container>
                {!children ? (
                  <Modal.Footer justify fullSize contentWidth="800px">
                    <Button type="button" transparent onlyIcon={isMobile} onClick={closeModal}>
                      {isMobile ? 'P{' : 'Отмена'}
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        form.change('action', 'create');
                      }}
                    >
                      Создать
                    </Button>
                  </Modal.Footer>
                ) : (
                  <Modal.Footer fullSize contentWidth="800px">
                    {children}
                  </Modal.Footer>
                )}
              </Modal.FullSizeContent>
            </Modal.FullSizeContainer>
          </form>
        );
      }}
    />
  );
};

export default withCheckIsMobile(QnAListForm);
