import Button from 'components/Button';
import { LangForm } from 'components/Form';
import {
  Textarea,
  FileCropperInput,
  InputWithSelect,
  FormDescription,
  SmallFormDescription,
  FormControl,
  FileArrayInput,
  LangSwitcher,
  Input,
} from 'components/FormControls';
import OptionWithPlaceInfo from 'components/FormControls/InputWithSelect/OptionWithPlaceInfo';
import Modal from 'components/Modal';
import StepModal from 'components/Modals/StepModal';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import LandingsModalLayout from 'containers/Pages/Landings/landingsModalLayout';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Editor } from 'entities/editor';
import { referencePlaceSources } from 'shared/api/reference/places';
import { AttachmentType } from 'shared/constants/ATTACHMENTS_TYPE';
import {
  STEPS_PREVIEW,
  ASPECT_RATIO_PREVIEW,
  STEPS_WORDS_PREVIEW,
  SIZES_PREVIEW,
  LANDINGS_PREVIEW_IMAGE_SIZES,
} from 'shared/constants/IMAGE_CONFIG';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderContentGroups, renderReferencePlaces } from 'shared/helpers/optionsRenderers';
import {
  composeValidators,
  required,
  maxValue,
  landingDescriptionValidation,
} from 'shared/helpers/validations';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import landingsTagsSources from 'shared/sources/landings/landingsTags';
import qna from 'shared/sources/reference/qna';
import { ModalFunctions } from '../../../../../interfaces/modal';
import CloseIcon from '../../../../../static/icons/close.svg';
import { selectAccount } from '../../../../entities/account/reducer';
import InputStyled from '../../events/EventsModal/styled';

interface FormProps {
  title: string;
  closeModal: ModalFunctions['closeModal'];
  onChangeLng?: (lng: string) => any;
  isDesktop: boolean;
  isMobile: boolean;
  isEdit?: boolean;
  initialValues?: any;
  children?: any;
  lng: string;
}

let finalForm;

const PlaygroundForm: React.FC<FormProps> = ({
  children,
  title,
  closeModal,
  isMobile,
  isDesktop,
  isEdit,
  onChangeLng,
  lng,
  ...rest
}) => {
  const account = useSelector(selectAccount);

  const { t } = useTranslation();

  const resetAttachmentInput = (attachmentCode) => {
    finalForm.batch(() => {
      finalForm.change(`${lng}.${attachmentCode}_title`, '');
      finalForm.change(`${lng}.${attachmentCode}_alt`, '');
    });
  };

  const currentValues = useRef({});

  const handleChange = (selectedLang: string) => {
    onChangeLng(selectedLang);
    currentValues.current = {};
  };

  return (
    <LangForm
      stretch
      {...rest}
      initialValues={{
        gallery: [],
        ...rest.initialValues,
        ...currentValues.current[lng],
        company_id: account.company.id,
      }}
      stashLanguages={!isEdit}
      onChangeLng={handleChange}
      render={({ handleSubmit, form, i18n, switcher, values }) => {
        finalForm = form;
        const disabledByLanguage = i18n.language !== LANGUAGES.RU;

        const hasValueCover = !isEmpty(values[i18n.language]?.cover);
        const hasValuePreview = !isEmpty(values[i18n.language]?.preview);

        return (
          <form onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={(changeProps) => {
                currentValues.current[i18n.language] = changeProps.values[i18n.language];
              }}
            />
            <LandingsModalLayout.GlobalStyles />
            <Modal.FullSizeContainer>
              <StepModal.Header>
                <StepModal.WithLanguage>
                  <StepModal.Title>{title}</StepModal.Title>
                  {isDesktop && <LangSwitcher />}
                </StepModal.WithLanguage>
              </StepModal.Header>
              <Modal.FullSizeContent>
                <Modal.Container>
                  <Field name={`${i18n.language}.id`} component="input" type="hidden" />
                  <Modal.Section>
                    {!isDesktop && <LandingsModalLayout.Row>{switcher}</LandingsModalLayout.Row>}
                    {!disabledByLanguage && (
                      <LandingsModalLayout.Row grid>
                        <FormDescription width={208}>Площадка из справочников</FormDescription>
                        <LandingsModalLayout.Column>
                          <FormControl>
                            <Field name={`${i18n.language}.place`} validate={isEdit ? undefined : required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  placeholder="Площадка"
                                  route={referencePlaceSources}
                                  disabled={isEdit}
                                  query={{ language_code: LANGUAGES.RU }}
                                  optionsRenderer={renderReferencePlaces}
                                  searchQueryName="search_string"
                                  components={{ Option: OptionWithPlaceInfo }}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Column>
                      </LandingsModalLayout.Row>
                    )}
                  </Modal.Section>
                  <Modal.Section>
                    <LandingsModalLayout.Row grid>
                      <FormDescription width={208}>
                        Заголовок и анонс
                        <SmallFormDescription>Заголовок о площадке и краткий анонс</SmallFormDescription>
                      </FormDescription>
                      <FormControl column>
                        <LandingsModalLayout.Row>
                          <Field
                            name={`${i18n.language}.title`}
                            validate={composeValidators(required, maxValue(255))}
                          >
                            {({ input, meta }) => (
                              <Textarea label="Заголовок" autoFocus maxValue={255} {...input} meta={meta} />
                            )}
                          </Field>
                        </LandingsModalLayout.Row>
                        <LandingsModalLayout.Row>
                          <Field
                            name={`${i18n.language}.annotation`}
                            validate={composeValidators(required, maxValue(255))}
                          >
                            {({ input, meta }) => (
                              <Textarea label="Анонс" maxValue={255} {...input} meta={meta} />
                            )}
                          </Field>
                        </LandingsModalLayout.Row>
                        <LandingsModalLayout.Row>
                          <Field name={`${i18n.language}.address`} validate={required}>
                            {({ input, meta }) => <Input label="Адрес площадки" meta={meta} {...input} />}
                          </Field>
                        </LandingsModalLayout.Row>
                      </FormControl>
                    </LandingsModalLayout.Row>
                  </Modal.Section>

                  {i18n.language === LANGUAGES.RU ? (
                    <>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            Теги
                            <SmallFormDescription>Теги для привязки площадки к сайту</SmallFormDescription>
                          </FormDescription>
                          <FormControl>
                            <Field name={`${i18n.language}.contentGroups`} validate={required}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  isMulti
                                  isCreatable
                                  label="Теги"
                                  searchQueryName="search_string"
                                  optionsRenderer={renderContentGroups}
                                  route={landingsTagsSources}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            {t('landings:events.qna')}
                            <SmallFormDescription>
                              {t('landings:events.qna_description')}
                            </SmallFormDescription>
                          </FormDescription>
                          <FormControl>
                            <Field name={`${i18n.language}.qna`}>
                              {({ input, meta }) => (
                                <InputWithSelect
                                  isAsync
                                  isMulti
                                  label={t('landings:events.qna')}
                                  searchQueryName="search_string"
                                  optionsRenderer={renderContentGroups}
                                  route={qna}
                                  meta={meta}
                                  {...input}
                                />
                              )}
                            </Field>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            Изображения
                            <SmallFormDescription>Главное изображение и превью</SmallFormDescription>
                          </FormDescription>
                          <FormControl column>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.cover`}>
                                {({ input, meta }) => (
                                  <FileCropperInput
                                    placeholder="Загрузить Обложку"
                                    // eslint-disable-next-line
                                    description="Рекомендуемый размер Главного изображения 1920х960px (16:9), не более 5MB"
                                    {...input}
                                    meta={meta}
                                    attachmentCode={AttachmentType.Cover}
                                    resetAttachmentForm={resetAttachmentInput}
                                  />
                                )}
                              </Field>
                              {hasValueCover && (
                                <>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.cover_title`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:cover_title')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.cover_alt`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:cover_alt')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                </>
                              )}
                            </LandingsModalLayout.Row>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.preview`}>
                                {({ input, meta }) => (
                                  <FileCropperInput
                                    placeholder="Загрузить Превью"
                                    description="Рекомендуемый размер Превью 480х320px, не более 2MB"
                                    {...input}
                                    meta={meta}
                                    stepsEnum={STEPS_PREVIEW}
                                    ratio={ASPECT_RATIO_PREVIEW}
                                    words={STEPS_WORDS_PREVIEW}
                                    sizes={SIZES_PREVIEW}
                                    imageSizes={LANDINGS_PREVIEW_IMAGE_SIZES}
                                    attachmentCode={AttachmentType.Preview}
                                    resetAttachmentForm={resetAttachmentInput}
                                  />
                                )}
                              </Field>
                              {hasValuePreview && (
                                <>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.preview_title`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:preview_title')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.preview_alt`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:preview_alt')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                </>
                              )}
                            </LandingsModalLayout.Row>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                      <Modal.Section>
                        <LandingsModalLayout.Row grid>
                          <FormDescription width={208}>
                            Галерея
                            <SmallFormDescription>Коллеция изображений площадки</SmallFormDescription>
                          </FormDescription>
                          <FormControl column>
                            <LandingsModalLayout.Row>
                              <Field name={`${i18n.language}.gallery`}>
                                {({ input, meta }) => <FileArrayInput {...input} meta={meta} />}
                              </Field>
                            </LandingsModalLayout.Row>
                          </FormControl>
                        </LandingsModalLayout.Row>
                      </Modal.Section>
                    </>
                  ) : (
                    <>
                      {(hasValueCover || hasValuePreview) && (
                        <Modal.Section>
                          <LandingsModalLayout.Row grid>
                            <FormDescription width={208}>
                              Изображения
                              <SmallFormDescription>Главное изображение и превью</SmallFormDescription>
                            </FormDescription>
                            <FormControl column>
                              {hasValueCover && (
                                <LandingsModalLayout.Row>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.cover_title`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:cover_title')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.cover_alt`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:cover_alt')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                </LandingsModalLayout.Row>
                              )}

                              {hasValuePreview && (
                                <LandingsModalLayout.Row>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.preview_title`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:preview_title')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                  <InputStyled.InputWrapper auto>
                                    <Field name={`${i18n.language}.preview_alt`}>
                                      {({ input, meta }) => (
                                        <Input label={t('forms:preview_alt')} {...input} meta={meta} />
                                      )}
                                    </Field>
                                  </InputStyled.InputWrapper>
                                </LandingsModalLayout.Row>
                              )}
                            </FormControl>
                          </LandingsModalLayout.Row>
                        </Modal.Section>
                      )}
                    </>
                  )}
                  <Modal.Editor>
                    <Field name={`${i18n.language}.description`} validate={landingDescriptionValidation}>
                      {({ input, meta }) => (
                        <Editor
                          label="Описание"
                          id="place_1"
                          key={rest.initialValues?.text || i18n.language}
                          inModal
                          input={input}
                          meta={meta}
                        />
                      )}
                    </Field>
                  </Modal.Editor>
                </Modal.Container>

                {!children ? (
                  <Modal.Footer justify fullSize contentWidth="800px">
                    <Button type="button" transparent onlyIcon={isMobile} onClick={closeModal}>
                      {isMobile ? <CloseIcon /> : 'Отмена'}
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        form.change('action', 'create');
                      }}
                    >
                      Создать
                    </Button>
                  </Modal.Footer>
                ) : (
                  <Modal.Footer fullSize contentWidth="800px">
                    {children}
                  </Modal.Footer>
                )}
              </Modal.FullSizeContent>
            </Modal.FullSizeContainer>
          </form>
        );
      }}
    />
  );
};

export default withCheckIsMobile(PlaygroundForm);
