import React from 'react';
import omit from 'lodash/omit';
import { connect } from 'react-redux';
import MODAL_STATES from 'shared/constants/MODAL_STATES';
import Button from 'components/Button';
import TagForm from './TagForm';
import landingsTagsSources from 'shared/sources/landings/landingsTags';
import withLandingData from '../withLandingData';
import withCheckIsMobile from 'shared/lib/withCheckIsMobile';
import CloseIcon from '../../../../../static/icons/close.svg';
import PAGES from 'shared/constants/PAGES';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { IUser } from '../../../../entities/account/types';

interface ModalProps {
  closeModal: ModalFunctions['closeModal'];
  modalState: string;
  data: any;
  isMobile: boolean;
  updateList: () => void;
  create: (data: any) => any;
  update: (data: any) => any;
  onUpdate: (data: any) => void;
  showDeleteModal: () => void;
  account: IUser;
}

const TagModal: React.FC<ModalProps> = (props) => {
  const { data: modalData, updateList, modalState, onUpdate, create, update, isMobile, account } = props;

  switch (modalState) {
    case MODAL_STATES.CREATE: {
      return (
        <TagForm
          initialValues={{ company_id: account.company.id }}
          key="create"
          title="Добавление нового тега"
          onSubmit={create}
          onSuccess={updateList}
        >
          <Button type="button" transparent onClick={props.closeModal}>
            Отмена
          </Button>
          <Button>Создать</Button>
        </TagForm>
      );
    }

    default: {
      return (
        <TagForm
          key="edit"
          title="Редактирование тега"
          onSubmit={update}
          onSuccess={onUpdate}
          initialValues={omit(modalData, ['published', 'company', 'date_create', 'date_edit'])}
        >
          <Button type="button" transparent onlyIcon={isMobile} onClick={props.closeModal}>
            {isMobile ? <CloseIcon /> : 'Отмена'}
          </Button>
          <Button type="button" dangerTransparent onClick={props.showDeleteModal}>
            Удалить
          </Button>
          <Button>Сохранить</Button>
        </TagForm>
      );
    }
  }
};

const mapStateToProps = (state) => ({
  account: state.account.data,
});

export default withCheckIsMobile(
  withLandingData(connect(mapStateToProps)(TagModal), {
    translation: 'tags',
    sources: landingsTagsSources,
    page: PAGES.LANDING_TAGS,
    mappedCreateData: (submitData) => submitData,
    mappedUpdateData: (submitData) => submitData,
  }),
);
