import { find } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { currencySources } from 'entities/currency';
import CONSTRUCTOR_FIELDS from 'shared/constants/CONSTRUCTOR_FIELDS';
import CONSTRUCTOR_FILTERS from 'shared/constants/CONSTRUCTOR_FILTERS';
import CONSTRUCTOR_MODULES from 'shared/constants/CONSTRUCTOR_MODULES_NEW';
import { LANGUAGES, LANGUAGES_NAMES } from 'shared/constants/LANGUAGES';
import SUBMIT_ACTIONS from 'shared/constants/SUBMIT_ACTIONS';
import { getLanguageCurrency } from 'shared/helpers/currency';
import { checkForInitialValues } from 'shared/helpers/form';
import useListItem from 'shared/lib/useListItem';
import api from 'shared/services/api';
import landingsSitesSources from 'shared/sources/landings/landingsSites';
import localesSource from 'shared/sources/locales';
import { Select } from '../../../FormControls';
import StepModal from '../../../Modals/StepModal';
import ProgressBar from '../../../ProgressBar';
import Spinner from '../../../Spinner';
import Styled from './styles';

const ConstructorModal = (props: any) => {
  const modalId = props.data.tech_name || props.data.id;
  const [state, setState] = useState({
    step: 0,
    lng: LANGUAGES.RU,
    data: null,
    initialValues: null,
    availableCurrencies: [],
    locales: [],
    createTranslations: false,
    isLoading: true,
  });
  const StepComponent: any =
    CONSTRUCTOR_MODULES[state.lng === LANGUAGES.RU ? LANGUAGES.RU : 'translations'].STEP_COMPONENT[
      state.step
    ];
  const { get } = useListItem({ source: landingsSitesSources, newDetailApi: true });
  let updatedTempData = null;

  const loadData = ({ lang, currencies }) =>
    get(modalId, lang).then((responseData) => {
      const is404 = responseData.status === 404;

      const currentData = {
        ...responseData,
        ...(responseData.acquiring_list && {
          site_acquiring_config: responseData.acquiring_list[0],
          site_acquiring_second_config: responseData.acquiring_list[1],
        }),
        ...(responseData.qna && {
          qna:
            responseData.qna?.map((item) => ({
              value: item.id,
              label: item.title,
            })) || [],
        }),
        ...(!isEmpty(responseData.site_atol_config) && {
          site_atol_config: {
            value: responseData.site_atol_config.id,
            label: responseData.site_atol_config.company_name,
          },
        }),
        ...(!isEmpty(responseData.email_config) && {
          email_config: {
            value: responseData.email_config.id,
            label: responseData.email_config.login,
          },
        }),
        ...(!isEmpty(responseData.contact_info_address) && {
          contact_info_address: responseData.contact_info_address,
        }),
        ui_config_qna_name: responseData.ui_config_qna_name || 'Вопросы и ответы',
        favicon_type: responseData.ui_config_favicon && responseData.ui_config_favicon[0],
        favicon_color: responseData.ui_config_favicon && responseData.ui_config_favicon[1],
        ui_config_custom_pages_group_name: responseData.ui_config_custom_pages_group_name || 'Еще',
        ui_config_tabs: responseData.ui_config_tabs || 1,
        ui_config_container_color: responseData.ui_config_container_color || '#FFFFFF',
        ui_config_container_text_color: responseData.ui_config_container_text_color || '#000000',
        ui_config_index_ordering:
          responseData.ui_config_index_ordering &&
          responseData.ui_config_index_ordering.filter((i) => i !== 'archive'),
        ...(!isEmpty(responseData.ui_config_filters_list) && {
          ui_config_filters_list: responseData.ui_config_filters_list.map(
            (filter) => CONSTRUCTOR_FILTERS[filter],
          ),
        }),
        ui_config_titles:
          CONSTRUCTOR_FIELDS.titles[responseData.ui_config_titles] || CONSTRUCTOR_FIELDS.titles[1],
        ui_config_layout:
          CONSTRUCTOR_FIELDS.layout[responseData.ui_config_layout] || CONSTRUCTOR_FIELDS.layout[1],
        ...(responseData.contact_info_phone && {
          contact_info_phone: {
            label: responseData.contact_info_phone,
            value: responseData.contact_info_phone,
          },
        }),
        ...(responseData.contact_info_second_phone && {
          contact_info_second_phone: {
            label: responseData.contact_info_second_phone,
            value: responseData.contact_info_second_phone,
          },
        }),
        ...(!isEmpty(responseData.languages) && {
          languages: responseData.languages.map((language) => ({
            label: LANGUAGES_NAMES[language],
            value: language,
          })),
        }),
        ...(!isEmpty(responseData.main_lang) && {
          main_lang: {
            label: LANGUAGES_NAMES[responseData.main_lang],
            value: responseData.main_lang,
          },
        }),
        ...(!isEmpty(responseData.currencies) && {
          currencies: responseData.currencies.map((currency) =>
            find(currencies, (o) => o.value === currency.code),
          ),
        }),
      };

      let data = {
        data: currentData,
        initialValues: currentData,
        isLoading: false,
      };

      if (is404) {
        const translatedData = {
          site_name: state.data.site_name,
          lang,
          item: modalId,
          ui_config_show_media_name: null,
          ui_config_show_news_name: null,
          ui_config_show_afisha_name: null,
          ui_config_show_places_name: null,
          ui_config_show_persons_name: null,
          ui_config_show_repertoire_name: null,
          ui_config_show_additional_name: null,
          ui_config_show_archive_name: null,
          ui_config_block_name_afisha: null,
          ui_config_block_name_gallery: null,
          ui_config_block_name_top_afisha: null,
          ui_config_block_name_news: null,
          ui_config_block_name_faq: null,
          ui_config_block_name_contact: null,
          ui_config_block_name_address: null,
          ui_config_block_name_issue: null,
          ui_config_block_name_archive: null,
        };
        data = {
          data: translatedData,
          initialValues: translatedData,
          isLoading: false,
        };
      }

      setState((prevState) => ({
        ...prevState,
        ...data,
        createTranslations: is404,
      }));

      updatedTempData = data;

      return data;
    });

  useEffect(() => {
    api
      .get(currencySources.root)
      .then((response) => {
        const currencies = response.data.data.results.map((item) => ({
          value: item.char_code,
          label: item.name,
        }));
        loadData({
          lang: state.lng,
          currencies,
        }).catch((err) => new Error(err));
        setState((prevState) => ({
          ...prevState,
          availableCurrencies: currencies,
        }));
      })
      .catch((err) => new Error(err));
    api
      .get(localesSource.available)
      .then((response) => {
        const supportedLocales = response.data.map((item) => ({
          value: item.code,
          name: item.label,
        }));
        setState((prevState) => ({
          ...prevState,
          locales: [...prevState.locales, ...supportedLocales],
        }));
      })
      .catch((err) => new Error(err));
  }, []);

  const onChangeLng = ({ target: { value: newLng } }) => {
    setState((prevState) => ({
      ...prevState,
      lng: newLng,
      step: 0,
    }));
    loadData({ lang: newLng, currencies: state.availableCurrencies }).catch((err) => new Error(err));
  };

  const goBack = () => {
    setState((prevState) => ({
      ...prevState,
      step: prevState.step - 1,
    }));
  };

  const updateTempData = (newData) => {
    updatedTempData = newData;
  };

  const addData = (newData) => {
    const currentData = { ...state.data, ...checkForInitialValues(state.initialValues, newData) };
    const siteAcquiringSecondConfig = currentData.site_acquiring_second_config
      ? currentData.site_acquiring_second_config.value || currentData.site_acquiring_second_config
      : currentData.site_acquiring_second_config;
    const siteAcquiringConfig = currentData.site_acquiring_config
      ? currentData.site_acquiring_config.value || currentData.site_acquiring_config
      : null;
    if (
      state.step ===
        CONSTRUCTOR_MODULES[state.lng === LANGUAGES.RU ? LANGUAGES.RU : 'translations'].STEPS_WORDS.length -
          1 ||
      newData.submit_action === SUBMIT_ACTIONS.CREATE
    ) {
      const submitData =
        state.lng !== LANGUAGES.RU
          ? {
              ...currentData,
              ...(currentData.contact_info_address && {
                contact_info_address: currentData.contact_info_address,
              }),
              id: modalId,
            }
          : omit(
              {
                ...currentData,
                ...(currentData.content_groups && {
                  content_groups: currentData.content_groups.map((item) => item.id || item),
                }),
                ui_config_show_repertoire: false,
                ui_config_show_archive: false,
                legal_information: currentData.legal_information?.id,
                template_set: currentData.template_set?.id,
                acquiring_list: [siteAcquiringConfig, siteAcquiringSecondConfig].filter(Boolean),
                site_atol_config_id: currentData.site_atol_config
                  ? currentData.site_atol_config.value || currentData.site_atol_config
                  : null,
                email_config_id: currentData.email_config
                  ? currentData.email_config.value || currentData.email_config
                  : null,
                ui_config_filters_list: currentData.ui_config_filters_list
                  ? currentData.ui_config_filters_list.map((filter) => filter.value || filter)
                  : [],
                languages:
                  currentData.languages && currentData.ui_config_show_translation
                    ? currentData.languages.map((language) => language.value || language)
                    : [],
                ...(currentData.contact_info_address && {
                  contact_info_address: currentData.contact_info_address,
                }),
                main_lang:
                  currentData.main_lang && currentData.ui_config_show_translation
                    ? currentData.main_lang.value || currentData.main_lang
                    : LANGUAGES.RU,
                currencies: currentData.currencies
                  ? currentData.currencies.map((currency) => currency.value || currency)
                  : [],
                main_page_gallery:
                  currentData.main_page_gallery &&
                  currentData.main_page_gallery.map((item) => item.img || item),
                main_page_gallery_webp:
                  currentData.main_page_gallery &&
                  currentData.main_page_gallery.map((item) => item.img_webp || item),
                contact_info_phone: currentData.contact_info_phone
                  ? currentData.contact_info_phone.label || currentData.contact_info_phone
                  : null,
                ui_config_layout: currentData.ui_config_layout
                  ? currentData.ui_config_layout.value || currentData.ui_config_layout
                  : null,
                ui_config_titles: currentData.ui_config_titles
                  ? currentData.ui_config_titles.value || currentData.ui_config_titles
                  : null,
                contact_info_second_phone: currentData.contact_info_second_phone
                  ? currentData.contact_info_second_phone.label || currentData.contact_info_second_phone
                  : null,
                ...getLanguageCurrency(currentData),
              },
              ['favicon_type', 'favicon_color', 'triggers', 'color', 'rounding_corners'],
            );

      let update = null;

      if (state.lng !== LANGUAGES.RU) {
        const method = state.createTranslations
          ? api.post(`${landingsSitesSources.detail(modalId)}/translation`, submitData)
          : api.patch(`${landingsSitesSources.detail(modalId)}/translation/${state.lng}`, submitData);
        update = method;
      } else {
        update = api.put(landingsSitesSources.detail(modalId), submitData);
      }

      const promises = [update];

      return Promise.all(promises)
        .then(([updateResponse]) => {
          if (updateResponse.status === 400) {
            const errors = updateResponse.data.filter((item) => item.error);
            return errors.map((error) => {
              if (error.type === 'LANDINGS.BULK_UPDATE_FAIL') {
                return NotificationManager.error('Проверьте заполненность полей', 'Ошибка');
              }

              return NotificationManager.error('Ошибка');
            });
          }

          setState((prevState) => ({
            ...prevState,
            createTranslations: false,
          }));
          return NotificationManager.success('Успешно');
        })
        .catch((err) => new Error(err));
    }

    setState((prevState) => ({
      ...prevState,
      data: currentData,
      step: prevState.step + 1,
    }));
  };

  const selectStep = (selectedStep) => {
    setState((prevState) => ({
      ...prevState,
      ...(updatedTempData && {
        data: updatedTempData,
      }),
      step: selectedStep,
    }));
  };

  return (
    <>
      <Styled.GlobalStyles />
      <StepModal.Header withMobileSteps>
        <StepModal.Title>
          Конструктор <Styled.TitleLabel>сайта</Styled.TitleLabel>{' '}
          {!state.isLoading && `/ ${state.data.site_name}`}
          <Styled.LangSwitcher>
            <Select small options={state.locales} value={state.lng} onChange={onChangeLng} />
          </Styled.LangSwitcher>
        </StepModal.Title>
      </StepModal.Header>
      <Styled.Main>
        <Styled.Progress>
          <ProgressBar
            isVertical
            mobileHorizontal
            steps={
              CONSTRUCTOR_MODULES[state.lng === LANGUAGES.RU ? LANGUAGES.RU : 'translations'].STEPS_WORDS
            }
            currentStep={state.step}
            onSelect={selectStep}
          />
        </Styled.Progress>
        {state.isLoading ? (
          <Spinner size={50} />
        ) : (
          <StepComponent
            onSubmit={addData}
            goBack={goBack}
            data={state.data}
            updateData={updateTempData}
            lng={state.lng}
          />
        )}
      </Styled.Main>
    </>
  );
};

export default ConstructorModal;
