import Placeholder from 'components/Placeholder';
import SplittedNumber from 'components/SplittedNumber';
import permissions from 'config/permissions';
import Can from 'containers/Can';
import Layout from 'containers/Layout';
import { withRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { noun } from 'plural-ru';
import React from 'react';
import { MainLayout } from 'widgets/layouts/main-layout';
import { ReferenceLayout } from 'widgets/layouts/reference-layout';
import { ReferenceCreateButton } from 'widgets/reference/reference-create-button';
import { ReferenceSearch } from 'widgets/reference/reference-search';
import REFERENCE_TABS from 'shared/constants/REFERENCE_TABS';
import { useToggler } from 'shared/lib/toggler';
import { useList } from 'shared/lib/use-list';
import { ReferenceTabs } from 'shared/ui/reference/tabs';
import { Spinner } from 'shared/ui/spinner';
import { $data, $loading, pageMounted } from '../model';
import { CreateHallModal, createHallModal } from './create-modal';
import { CreateSchemeModal } from './create-scheme-modal';
import { HallsList } from './list';
import { UpdateHallModal } from './update-modal';
import { UpdateSchemeModal } from './update-scheme-modal';
import { ViewHallModal } from './view-modal';

const ReferencePage = () => {
  const { open: openCreateModal } = useToggler(createHallModal.toggler);
  const name = REFERENCE_TABS.HALLS;
  const { t } = useTranslation();
  const { data } = useList({
    mountPage: pageMounted,
    loading: $loading,
    data: $data,
  });
  const { loading, data: hallItems } = data;

  return (
    <MainLayout title={t('references:title')}>
      <Layout disableLayout withoutPaddingsOnMobile title={t('references:title')}>
        <ReferenceTabs loading={loading}>
          <ReferenceLayout.MainWrapper>
            <ReferenceLayout.Header>
              {!loading ? (
                <div>
                  <SplittedNumber>{hallItems?.count}</SplittedNumber>{' '}
                  <ReferenceLayout.CountLabel>
                    {noun(hallItems?.count, 'Зал', 'Зала', 'Залов')}
                  </ReferenceLayout.CountLabel>
                </div>
              ) : (
                <ReferenceLayout.PlaceholderContainer>
                  <Placeholder />
                </ReferenceLayout.PlaceholderContainer>
              )}
              <ReferenceLayout.Actions>
                <ReferenceSearch referenceName={name} />
                <ReferenceLayout.HeaderControls>
                  <Can permission={permissions.reference}>
                    <ReferenceCreateButton referenceName={name} onClick={openCreateModal} />
                  </Can>
                </ReferenceLayout.HeaderControls>
              </ReferenceLayout.Actions>
            </ReferenceLayout.Header>
            {loading ? (
              <Spinner center />
            ) : (
              <ReferenceLayout.Wrapper>
                <HallsList data={hallItems} />
              </ReferenceLayout.Wrapper>
            )}
          </ReferenceLayout.MainWrapper>
        </ReferenceTabs>
      </Layout>
      <CreateHallModal />
      <UpdateHallModal />
      <ViewHallModal />
      <CreateSchemeModal />
      <UpdateSchemeModal />
    </MainLayout>
  );
};

export const ReferenceHallsPage = withRouter(ReferencePage);
