import Pagination from 'components/Pagination';
import { PlainTable } from 'components/Table';
import React from 'react';
import { ReferenceLayout } from 'widgets/layouts/reference-layout';
import { Places } from 'shared/api/reference/places';
import { PlaceListItem } from './item';

interface ListProps {
  data: Places;
}

export const PlacesList: React.FC<ListProps> = ({ data }) => {
  const { results, ...meta } = data;

  return (
    <ReferenceLayout.Content>
      <ReferenceLayout.List mobileFluid>
        <PlainTable.Table mobileDisableBorder>
          <PlainTable.Thead mobileHide>
            <PlainTable.Tr>
              <PlainTable.Th>Название</PlainTable.Th>
              <PlainTable.Th>Город</PlainTable.Th>
              <PlainTable.Th />
            </PlainTable.Tr>
          </PlainTable.Thead>
          <PlainTable.Tbody>
            {results.map((item) => (
              <PlaceListItem key={item.id} data={item} />
            ))}
          </PlainTable.Tbody>
        </PlainTable.Table>
      </ReferenceLayout.List>

      <ReferenceLayout.Nav>
        <Pagination limits={[25, 50, 100, 150]} meta={meta} updateQuery />
      </ReferenceLayout.Nav>
    </ReferenceLayout.Content>
  );
};
