import styled from 'styled-components';
import { getBorderOnError } from 'shared/helpers/input';

export default {
  Overlay: styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,

  Container: styled.div<any>`
    position: relative;

    .ant-time-picker {
      width: 100%;
    }

    .ant-time-picker-input {
      ${getBorderOnError};
      height: 40px;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .ant-time-picker-panel-select {
      overflow-y: auto;
    }
  `,
};
